<script>
export default {
    props: {
        inverted: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: 'normal',
        },
    },
}
</script>

<template>
    <div :class="[$style.sectionSubsection, $style.subsectionGraphics]">
        <div class="container">
            <div :class="[$style.subsectionWrapper, inverted ? $style.inverted : '']">
                <div :class="[$style.sectionParagraph, size === 'normal' ? $style.paragraphNormal : '']">
                    <h3 :class="$style.subheadline">
                        Des graphismes fulgurants<br class="medium" />
                        pour les jeux et les apps.
                    </h3>
                    <p :class="$style.intro">
                        Le moteur graphique à quatre cœurs de la puce A12 Bionic livre des performances remarquables.
                        Expériences de réalité augmentée, effets photoréalistes dans les jeux 3D et graphismes
                        spectaculaires au sein des apps : l’image révèle une intensité inédite.
                    </p>
                </div>
                <div :class="[$style.imageWrapper]">
                    <figure> </figure>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.subsectionWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    &.inverted {
        justify-content: flex-end;
    }
}
.sectionSubsection {
    padding-top: 150px;
}
.sectionGraphics {
    padding-top: 100px;
}
.sectionParagraph {
    position: relative;
    z-index: 1;
    &.paragraphNormal {
        width: 45%;
    }
}
.subheadline {
    font-size: 48px;
    font-weight: 700;
    line-height: 1.08365;
    letter-spacing: 0;
}
.intro {
    margin-top: 1.5em;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33349;
    letter-spacing: 0.009em;
}
</style>
