export default [
    {
        path: '/services',
        component: require('@views/services').default,
        children: [
            {
                path: '',
                name: 'services',
                component: require('@views/service-live').default,
            },
        ],
    },
]
