import Vue from 'vue'

export const state = {
    jumps: [
        {
            id: 1,
            number: '1a',
            status: false,
            position: {
                x: 10,
                y: 20,
            },
        },
        {
            id: 2,
            number: 2,
            status: true,
            position: {
                x: 20,
                y: 80,
            },
        },
        {
            id: 3,
            number: 3,
            status: false,
            position: {
                x: 80,
                y: 85,
            },
        },
        {
            id: 4,
            number: 4,
            status: null,
            position: {
                x: 65,
                y: 20,
            },
        },
        {
            id: 5,
            number: 5,
            status: null,
            position: {
                x: 30,
                y: 65,
            },
        },
    ],
    time: 0,
    officialTime: [9000, 15000],
    penality: [8, 12],
}

export const mutations = {
    SET_JUMP_STATUS(state, { id, newValue }) {
        let index = state.jumps.map((x) => x.id).indexOf(id)
        Vue.set(state.jumps[index], 'status', newValue)
    },
}

export const getters = {
    jumps(state) {
        return state.jumps
    },
    jumpsActived(state) {
        return state.jumps.filter((jump, index, jumps) => jump.status !== null)
    },
    jumpsPath(state) {
        return state.jumps.filter((jump, index, jumps) => {
            const previous = jumps[index - 1]
            return jump.status !== null || (typeof previous !== 'undefined' && previous.status !== null)
        })
    },
    jumpActive(state, getters) {
        return getters.jumpsActived[getters.jumpsActived.length - 1]
    },
    officialTime(state) {
        if (Array.isArray(state.officialTime)) {
            return [transformTimeInSeconde(state.officialTime[0]), transformTimeInSeconde(state.officialTime[1])]
        } else {
            return transformTimeInSeconde(state.officialTime)
        }
    },
    time(state) {
        return transformTimeInSeconde(state.time)
    },
    penality(state) {
        return state.penality
    },
}

export const actions = {}

// ===
// Private helpers
// ===

function transformTimeInSeconde(time) {
    return time / 100
}
