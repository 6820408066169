<script>
export default {
    props: {
        hero: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div :class="[$style.section, hero ? $style.sectionHero : '']">
        <div :class="$style.sectionContent">
            <slot />
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.section {
    position: relative;
    z-index: 1;
    &.sectionHero {
        margin-top: -90px;
        padding-top: 90px;
        background: linear-gradient(#1a1a1f, black);
        .sectionContent {
            padding-top: 108px;
            @include mobile {
                padding-top: 62px;
                padding-bottom: 75px;
            }
            @include tablet-only {
                padding-top: 88px;
            }
        }
    }
}
.sectionContent {
    margin-left: auto;
    margin-right: auto;
    width: 980px;
    padding-top: 125px;
    padding-bottom: 75px;
    @include mobile {
        width: 87.5%;
        padding-top: 80px;
        padding-bottom: 30px;
    }
    @include tablet-only {
        width: 692px;
    }
}
</style>
