<script>
import { mapGetters } from 'vuex'
import FormError from '@components/form-error'
import design from '@design'
import i18n from '@src/i18n'

const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY)

export default {
    components: {
        FormError,
    },
    props: {
        tokenData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            submitting: false,
            style: {
                base: {
                    color: 'white',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    lineHeight: '44px',
                },
                invalid: {
                    color: design['global-color-error'],
                    iconColor: design['global-color-error'],
                },
            },
        }
    },
    computed: {
        ...mapGetters('form', ['hasErrors']),
        elements() {
            const locale = i18n.locale
            return stripe.elements({ locale })
        },
        cardNumber() {
            return this.elements.create('cardNumber', { style: this.style })
        },
        cardExpiry() {
            return this.elements.create('cardExpiry', { style: this.style })
        },
        cardCvc() {
            return this.elements.create('cardCvc', { style: this.style })
        },
    },
    mounted() {
        this.cardNumber.mount('#cardNumber')
        this.cardExpiry.mount('#cardExpiry')
        this.cardCvc.mount('#cardCvc')
        this.registerElements([this.cardNumber, this.cardExpiry, this.cardCvc])
    },
    methods: {
        registerElements(elements) {
            elements.forEach((input, key) => {
                input.on('change', (event) => {
                    if (event.error) {
                        this.$store.commit('form/SET_ERRORS', {
                            stripe: [event.error.message],
                        })
                    } else {
                        this.$store.dispatch('form/removeErrorIfExist', 'stripe')
                    }
                })
            })
        },
        submit() {
            this.submitting = true
            stripe.createToken(this.cardNumber, this.tokenData).then((result) => {
                if (result.error) {
                    this.$store.commit('form/SET_ERRORS', {
                        stripe: [result.error.message],
                    })
                    this.submitting = false
                }
                if (result.token) {
                    this.$emit('success', result.token)
                }
            })
        },
    },
}
</script>

<template>
    <form novalidate class="form" @submit.prevent="submit">
        <h2>
            {{ $t('form.payment.creditcard') }}
        </h2>
        <div :class="[$style.fields, hasErrors ? $style.hasErrors : '']">
            <div class="field">
                <div id="cardNumber" class="input" :class="$style.input" />
            </div>
            <div class="field fieldHalf">
                <div id="cardExpiry" class="input" :class="$style.input" />
            </div>
            <div class="field fieldHalf">
                <div id="cardCvc" class="input" :class="$style.input" />
            </div>
        </div>
        <FormError name="stripe" />
        <div :class="$style.footer">
            <BaseButton
                type="submit"
                :disabled="submitting || hasErrors"
                class="button buttonFull"
                :class="$style.button"
            >
                <BaseSpinner v-if="submitting" :class="$style.spinner" />
                <span :class="submitting ? $style.buttonDisappeared : ''">
                    {{ !hasErrors ? $t('form.button.pay') : $t('form.button.error') }}
                </span>
            </BaseButton>
        </div>
    </form>
</template>

<style lang="scss" module>
@import '@design';

.fields {
    display: flex;
    flex-wrap: wrap;
    border-radius: $border-radius;
    zoom: 1;
    margin: -$padding-field;
    &.hasErrors {
        margin: 0;
        box-shadow: inset 0 0 0px 1px #e0475f;
    }
}
.input {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.button {
    @include mobile {
        width: 100%;
    }
    span {
        display: block;
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        transform: scale(1);
        &.buttonDisappeared {
            opacity: 0;
            transition: all 0.3s ease-in-out 0s;
            transform: scale(0.5);
        }
    }
}
.spinner {
    padding: 5px;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;

    @include mobile {
        flex-direction: column-reverse;
    }
    .back {
        display: flex;
        align-items: center;

        @include mobile {
            padding-top: 1.5em;
            text-align: center;
        }
        .iconBack {
            width: 10px;
            height: 10px;
            margin-right: 0.25em;
            color: currentColor;
            fill: currentColor;
        }
    }
}
</style>

<style lang="scss" scoped>
@import '@design';

.StripeElement--invalid {
    border-color: $color-pink !important;
}
.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}
</style>
