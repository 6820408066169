import Vue from 'vue'
import App from './app'
import router from '@router'
import store from '@state/store'
import i18n from './i18n'
import './vendor'
import '@components/_globals'
import { currency, capitalize, capitalizeAll } from './filters'
import 'intersection-observer'

Vue.filter('currency', currency)
Vue.filter('capitalize', capitalize)
Vue.filter('capitalizeAll', capitalizeAll)

Vue.config.productionTip = process.env.NODE_ENV === 'production'

// If running inside Cypress...
if (window.Cypress) {
    // Ensure tests fail when Vue emits an error.
    Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app')
