<template>
    <div :class="$style.badge" :style="badgeCSS">
        <svg v-if="pulse" expanded="true" height="15px" width="15px">
            <circle cx="50%" cy="50%" r="3px"></circle>
            <circle cx="50%" cy="50%" r="7px" :class="$style.pulse"></circle>
        </svg>

        <div :class="pulse ? $style.text : ''">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: 'Badge',
    props: {
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: 'green',
        },
        textColor: {
            type: String,
            default: 'white',
        },
        light: {
            type: Boolean,
            default: false,
        },
        pulse: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        badgeCSS() {
            return this.light
                ? {
                      border: 'solid 1px ' + this.color,
                      color: this.color,
                  }
                : {
                      'background-color': this.color,
                      'color': this.textColor,
                  }
        },
    },
}
</script>

<style lang="scss" module>
@import '@design';

.badge {
    display: flex;
    padding: 3px 10px;
    border-radius: 5px;
    margin-top: -3px;
    margin-left: 5px;

    circle {
        stroke: $color-purple;
        fill: $color-purple;
        stroke-width: 2px;
        stroke-opacity: 1;
    }

    .pulse {
        fill: $color-purple;
        fill-opacity: 0;
        transform-origin: 50% 50%;
        animation-duration: 2s;
        animation-name: pulse;
        animation-iteration-count: infinite;
    }

    .text {
        margin-top: 2px;
        margin-left: 3px;
    }
}

@keyframes pulse {
    from {
        stroke-width: 3px;
        stroke-opacity: 1;
        transform: scale(0.3);
    }
    to {
        stroke-width: 0;
        stroke-opacity: 0;
        transform: scale(2);
    }
}
</style>
