<script>
import backgroundColor from '@components/mixins/backgroundColor'

export default {
    mixins: [backgroundColor],
}
</script>

<template>
    <div :class="$style.sectionIntro">
        <div v-if="$slots.icon" :class="$style.icon">
            <slot name="icon" />
        </div>
        <h2 class="commonSectionTitle">
            <slot name="title" />
        </h2>
        <p class="commonIntroText">
            <slot name="intro" />
        </p>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.sectionIntro {
    text-align: center;
}
.icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    background-color: $color-link;
    border-radius: 50%;
    svg {
        width: 48px;
        height: 48px;
    }
}
</style>
