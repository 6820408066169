<script>
import Layout from '@layouts/main'

export default {
    components: {
        Layout,
    },
}
</script>

<template>
    <Layout>
        <RouterView />
    </Layout>
</template>
