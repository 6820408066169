import Vue from 'vue'
import router from '@router'
import VueObserveVisibility from 'vue-observe-visibility'
import VueGtag from 'vue-gtag'
import 'pusher-js'
import Echo from 'laravel-echo'
import VModal from 'vue-js-modal'

Vue.use(VModal)
Vue.use(VueObserveVisibility)

if (process.env.NODE_ENV === 'production') {
    Vue.use(VueGtag, {
        config: { id: process.env.VUE_APP_GTAG ?? 'UA-75586249-1' },
    })
}

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
})
