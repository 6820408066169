<script>
import { mapGetters, mapActions } from 'vuex'
import ModalLayout from '@layouts/modal'

export default {
    name: 'ModalSessionDemo',
    components: {
        ModalLayout,
    },
    data() {
        return {}
    },
    computed: {
        webAppMoveUrl() {
            return process.env.VUE_APP_ROOT_APP
        },
    },
    created() {},
    methods: {
        slide(newTab) {},
    },
}
</script>

<template>
    <ModalLayout>
        <template #title>
            {{ $t('modal-session-demo.title') }}
        </template>
        <div :class="$style.subtitle">
            <p>
                {{ $t('modal-session-demo.from-mobile') }}
            </p>
            <div :class="$style.badges">
                <div>
                    <a href="https://apps.apple.com/ch/app/alogo/id1533246447?l=fr" target="_blank">
                        <img
                            :src="require(`@assets/images/badges/apple-store-${$i18n.locale}-white.svg`)"
                            alt="Apple Store badge"
                            :class="$style.badge"
                        />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=io.alogo.app" target="_blank">
                        <img
                            :src="require(`@assets/images/badges/google-play-${$i18n.locale}-black.png`)"
                            alt="Google Play badge"
                            :class="$style.badge"
                        />
                    </a>
                </div>
            </div>
            <p>
                {{ $t('modal-session-demo.from-browser') }}
            </p>
            <div :class="$style.badges">
                <div>
                    <a :href="webAppMoveUrl" target="_blank">
                        <img
                            :src="require(`@assets/images/badges/webapp-move-pro.png`)"
                            alt="WebApp Move"
                            :class="$style.badge"
                        />
                    </a>
                </div>
            </div>
        </div>

        <template #footer> </template>
    </ModalLayout>
</template>

<style lang="scss" module>
@import '@design';

.subtitle {
    font-size: 16px;
    display: inline-block;
    font-weight: 600;
    width: 100%;
    p {
        margin-top: 1rem;
    }
}

.badge {
    width: 130px;
    margin-bottom: 0.5rem;
}
.badges {
    display: flex;

    // flex-direction: column;
    justify-content: center;
    text-align: center;
}
</style>
