<script>
export default {
    props: {
        placeholder: {
            type: String,
            default: 'Rechercher...',
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>

<template>
    <input type="search" :placeholder="placeholder" :class="$style.search" v-on="listeners" />
</template>

<style lang="scss" module>
.search {
    position: relative;
    width: 100%;
    height: 40px;
    padding: 0 7px 0 32px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: 400;
    background-color: transparent;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48ZyBmaWxsPSIjODg5OEFBIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik01LjI1IDExYTUuMjUgNS4yNSAwIDEgMCAwLTEwLjUgNS4yNSA1LjI1IDAgMCAwIDAgMTAuNXptMC0uOTVhNC4zIDQuMyAwIDEgMCAwLTguNiA0LjMgNC4zIDAgMCAwIDAgOC42eiIvPjxwYXRoIGQ9Ik04LjU0MiA5LjY0NWwxLjA2MS0xLjA2MSAyLjU5NSAyLjU5NWEuNzQ4Ljc0OCAwIDAgMSAwIDEuMDYuNzQ4Ljc0OCAwIDAgMS0xLjA2IDBMOC41NDEgOS42NDV6Ii8+PC9nPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-position: 10px 12px;
    background-size: 13px 13px;
    border: 2px solid rgba(black, 0.1);
    border-radius: 8px;
    transition: box-shadow 0.3s ease-in-out 0s;
}
</style>
