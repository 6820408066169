<script>
export default {
    name: 'ModalLayout',
    props: {
        count: {
            type: Number,
            default: 0,
        },
        position: {
            type: Number,
            default: 0,
        },
    },
}
</script>

<template>
    <div>
        <div v-if="$slots.title" :class="$style.title">
            <slot name="title" />
        </div>
        <div v-if="$slots.subtitle" :class="$style.subtitle">
            <slot name="subtitle" />
        </div>
        <div v-if="$slots.title || $slots.subtitle" :class="[$style.separator, $style.separatorHighlight]" />
        <slot />
        <div v-if="$slots.button" :class="$style.button">
            <slot name="button" />
        </div>
        <div v-if="$slots.footer" :class="$style.footer">
            <slot name="footer" />
        </div>
        <div v-if="count" :class="$style.positions">
            <div v-for="n in count" :key="n" :class="[$style.position, position === n ? $style.positionActive : '']" />
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.title {
    margin-bottom: 3px;
    font-size: 24px;
    font-weight: 600;
}
.subtitle {
    font-size: 14px;
    strong {
        display: inline-block;
        font-weight: 600;
    }
    del {
        font-weight: 400;
        text-decoration: line-through;
    }
}
.separator {
    margin-top: 16px;
    margin-bottom: 16px;
    border-top: 1px solid hsla(0, 0%, 18%, 1);
    &.separatorHighlight {
        border-color: $color-link;
    }
}
.button {
    margin-top: 24px;
}
.footer {
    margin-top: 15px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33em;
    color: hsla(0, 0%, 40%, 1);
    text-align: center;
    word-wrap: break-word;
    strong {
        @include font-heading;

        font-weight: 800;
    }
}
.positions {
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    margin-top: 20px;
    .position {
        width: 7px;
        height: 7px;
        background: $color-background-dark-extra-soft;
        border-radius: $border-radius-round;
        &:not(:first-child) {
            margin-left: 5px;
        }
    }
    .positionActive {
        background: $color-link;
    }
}
</style>
