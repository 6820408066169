<script>
import Layout from '@layouts/main'

export default {
    components: { Layout },
}
</script>

<template>
    <Layout>
        <div class="container">
            <div :class="$style.noLive">
                <h2>Aucun Live</h2>
            </div>
        </div>

        <div class="container">
            <div :class="$style.nextEpreuves">
                <h3>Prochain Live</h3>
                <ul>
                    <li> Epreuve XYZ </li>
                    <li> Epreuve XYZ </li>
                    <li> Epreuve XYZ </li>
                </ul>
            </div>
        </div>
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.noLive {
    margin-bottom: 20px;
    color: white;
    text-align: center;
    background: black;
    h2 {
        @include font-heading;

        padding: 20px;
        font-size: 25px;
    }
}
.nextEpreuves {
    margin-bottom: 20px;
    text-align: center;
    background: $color-background-soft;
    h3 {
        @include font-heading;

        padding: 20px 20px 0;
        margin-bottom: 10px;
        font-size: 25px;
    }
    li {
        padding: 10px 20px;
        &:not(:first-child) {
            border-top: 1px solid white;
        }
    }
}
</style>
