<script>
import Modal from '@components/modal/modal'

export default {
    components: { Modal },
    props: {
        currentIntro: {
            type: Object,
            default: null,
        },
        showIntroModal: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<template>
    <Modal v-if="currentIntro" :isOpen="showIntroModal" @close="$emit('close')">
        <template #header>
            <div> {{ $t('products.move.intro.header') }} </div>
        </template>
        <template #body>
            <img
                :src="require(`@assets/images/intro/${$i18n.locale}/${currentIntro['image-name']}`)"
                alt="intro"
                :class="$style.img"
            />

            <div :class="$style.title">
                {{ currentIntro.title }}
            </div>

            <div :class="$style.texts">
                <div v-for="(text, indexText) in currentIntro.texts" :key="`text_${indexText}`"> {{ text }}</div>
            </div>
        </template>
    </Modal>
</template>

<style lang="scss" module>
.texts {
    margin-bottom: 30px;
    margin-top: 20px;

    div + div {
        margin-top: 15px;
    }
}

.img {
    border-radius: 10px;
    width: 100%;
}

.title {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 700;
}
</style>
