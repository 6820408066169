import { capitalize } from '@src/filters'

const mixin = {
    props: {
        color: {
            type: String,
            default: '',
            validator(value) {
                return ['', 'light', 'dark'].indexOf(value) !== -1
            },
        },
    },
    computed: {
        backgroundColorClass() {
            if (!this.color) return
            return `commonBackground${capitalize(this.color)}`
        },
    },
}

export default mixin
