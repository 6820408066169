<script>
import TweenLite from 'gsap/TweenLite'
import BadgesWebapp from '@components/badges-webapp'

export default {
    page: {
        title: 'Live',
    },
    components: { BadgesWebapp },
    data() {
        return {
            timer: null,
            time: 0,
            tweenedTime: 0,
            firstIntermediateTime: false,
            secondIntermediateTime: false,
        }
    },
    computed: {
        animatedTime() {
            return this.tweenedTime.toFixed(2)
        },
        webAppLiveUrl() {
            return process.env.VUE_APP_ROOT_LIVE_APP
        },
        webAppLiveRGSurl() {
            return process.env.VUE_APP_ROOT_LIVE_RGS_APP
        },
    },
    watch: {
        time: function (newTime) {
            TweenLite.to(this.$data, 0.5, { tweenedTime: newTime })
        },
    },
    mounted() {
        this.createTimer(Date.now())
    },
    beforeDestroy() {
        this.destroyTimer()
    },
    methods: {
        createTimer(startTime) {
            this.destroyTimer()
            this.timer = setInterval(() => {
                var elapsedTime = Date.now() - startTime
                if (elapsedTime > 3240) {
                    this.firstIntermediateTime = true
                }
                if (elapsedTime > 7870) {
                    this.secondIntermediateTime = true
                }
                if (elapsedTime > 12000) {
                    this.destroyTimer()
                    this.createTimer(Date.now())
                }
                this.time = (elapsedTime / 1000).toFixed(2)
            }, 100)
        },
        destroyTimer() {
            if (this.timer) {
                clearInterval(this.timer)
                this.time = 0
                this.firstIntermediateTime = false
                this.secondIntermediateTime = false
            }
        },
    },
}
</script>

<template>
    <div>
        <!-- Hero -->
        <BaseSection :hero="true" :class="$style.hero">
            <BaseHeroTitles title="Live" :intro="$t('services.live.hero.intro')" />
            <BaseSubsectionWrapper type="full" position="center">
                <video autoplay muted playsinline loop src="@assets/videos/home-live.mp4" :class="$style.heroVideo" />
                <BaseSectionParagraph position="center" size="big" :class="$style.heroIntroParagraphs">
                    <BaseTypographyOverviewSectionIntro>
                        {{ $t('services.live.hero.paragraph.before-link') }}
                        <BaseLink :href="webAppLiveUrl">{{ $t('services.live.hero.paragraph.link') }}</BaseLink>
                        {{ $t('services.live.hero.paragraph.after-link') }}

                        <BadgesWebapp display="live" :class="$style.badgeWebapp" />
                    </BaseTypographyOverviewSectionIntro>
                </BaseSectionParagraph>
            </BaseSubsectionWrapper>
        </BaseSection>

        <!-- Technologies -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('services.live.technologies.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <!-- Technologies : Superposition -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.technologies.superposition.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.technologies.superposition.intro') }}
                            <BaseLink
                                href="https://www.youtube.com/watch?v=iDdqDr0hsCg&list=PLnUWCMxzvEqi9DPy7VfVHfY3QXXt9PdFp"
                            >
                                <div :class="$style.superpositionYTplaylist">
                                    {{ $t('services.live.technologies.superposition.link') }}
                                </div>
                            </BaseLink>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>

                    <BaseSectionMedia>
                        <video
                            autoplay
                            muted
                            playsinline
                            loop
                            src="@assets/videos/live-superposition.mp4"
                            :class="$style.superpositionVideo"
                        />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
                <!-- Technologies : Automatic falses -->
                <!-- <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half" :class="$style.technologiesObstacleParagraph">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.technologies.automatic.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.technologies.automatic.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/obstacle-puce.png" :class="$style.technologiesObstacleMedia" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper> -->
                <!-- Technologies : Interim time -->
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.technologies.interim.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.technologies.interim.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.technologiesTimeMedia">
                        <div :class="$style.technologiesTimes">
                            <div :class="$style.technologiesIntermediateTimes">
                                <transition
                                    :enter-class="$style.transitionTimeEnter"
                                    :enter-active-class="$style.transitionTimeEnterActive"
                                    :leave-active-class="$style.transitionTimeLeaveActive"
                                    :leave-to-class="$style.transitionTimeLeaveTo"
                                >
                                    <div
                                        v-if="secondIntermediateTime"
                                        :class="$style.technologiesSecondIntermediateTime"
                                    >
                                        -1.87
                                    </div>
                                </transition>
                                <transition
                                    :enter-class="$style.transitionTimeEnter"
                                    :enter-active-class="$style.transitionTimeEnterActive"
                                    :leave-active-class="$style.transitionTimeLeaveActive"
                                    :leave-to-class="$style.transitionTimeLeaveTo"
                                >
                                    <div v-if="firstIntermediateTime" :class="$style.technologiesFirstIntermediateTime">
                                        +0.24
                                    </div>
                                </transition>
                            </div>
                            <div :class="$style.technologiesCurrentTime">
                                {{ animatedTime }}
                            </div>
                        </div>
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
                <!-- Technologies : Web app -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.technologies.webapp.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.technologies.webapp.intro') }}
                            <div :class="$style.technologieWebAppLinks">
                                <div>
                                    <BaseLink :href="webAppLiveUrl"> Web App Live </BaseLink>
                                </div>
                                <div>
                                    <BaseLink :href="webAppLiveRGSurl"> Rolex Grand Slam Second Screen</BaseLink>
                                </div>
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/app-mockup-webapp.png" :class="$style.serviceLiveWebApp" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
                <!-- Technologies : Tracking -->
                <!-- <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.technologies.tracking.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.technologies.tracking.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.serviceLiveTracking">
                        <img src="@assets/images/move-hero-small.png" :class="$style.serviceLiveTrackingImage" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper> -->
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Clients -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline position="center">
                {{ $t('services.live.clients.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <!-- Clients : Placed -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('services.live.clients.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('services.live.clients.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionQuote>
                        <template slot="title">
                            {{ $t('entreprise.about.testimonial.title') }}
                        </template>
                        <template slot="quote">
                            {{ $t('entreprise.about.testimonial.quote') }}
                        </template>
                        <template slot="by">
                            <span>Sophie Mottu Morel</span>
                            {{ $t('entreprise.about.testimonial.by') }}
                        </template>
                    </BaseSectionQuote>
                </BaseSubsectionWrapper>
                <BaseGlobalLogos :columns="3">
                    <template slot="logos">
                        <figure>
                            <img src="@assets/images/logo-rgs.jpg" />
                        </figure>
                        <figure>
                            <img src="@assets/vector/logo-chi-geneve.svg" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-the-dutch-masters.jpg" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-chio.jpg" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-spruge-meadows.jpg" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-paris.png" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-verbier.png" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-equissima.png" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-DHS-RDS.png" />
                        </figure>
                        <figure>
                            <img src="@assets/images/logo-doha.png" />
                        </figure>
                    </template>
                </BaseGlobalLogos>
            </BaseSectionSubsection>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.badge {
    width: 100px;
}

.hero {
    overflow-x: hidden;
}
.heroVideo {
    width: 100%;
    margin-top: 24px;
    border-radius: 16px;

    @include mobile {
        width: calc(100% + 20%);
        margin-left: -10%;
        border-radius: 0;
    }
}
.serviceLiveWebApp {
    width: 70%;
    margin: 0 auto;
}
.serviceLiveTracking {
    position: relative;
}
.serviceLiveTrackingImage {
    width: 300px;
    margin: 0 auto;
}

.technologiesObstacleMedia {
    width: 100%;

    @include tablet {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 800px;
    }
}
.technologiesObstacleMediaSecond {
    position: relative;
    width: 300px;
    margin-right: -526px;
}
.technologiesObstacleParagraph {
    width: 55%;
}
.technologiesTimeMedia {
    display: flex;
    align-items: center;
    justify-content: center;
}
.technologiesTimes {
    position: relative;
    font-family: $font-family;
    font-weight: 800;
}
.technologiesCurrentTime {
    width: 330px;
    font-size: 100px;
    line-height: 1;
    color: $color-text;

    @include mobile {
        margin-top: 50px;
        font-size: 80px;
    }
}
.technologiesIntermediateTimes {
    position: absolute;
    bottom: 110px;
    display: flex;
    flex-direction: column;

    @include mobile {
        bottom: 80px;
    }
}
.technologiesFirstIntermediateTime,
.technologiesSecondIntermediateTime {
    display: inline-block;
    padding: 3px 10px;
    font-size: 30px;
    border: 1px solid;
    border-radius: 6px;

    @include mobile {
        font-size: 20px;
    }
}
.technologiesFirstIntermediateTime {
    border-color: $color-error;
}
.technologiesSecondIntermediateTime {
    margin-bottom: 15px;
    border-color: $color-success;

    @include mobile {
        margin-bottom: 5px;
    }
}
.technologieWebAppLinks {
    margin-top: 20px;

    div:not(:first-child) {
        margin-top: 10px;
    }
}
.superpositionYTplaylist {
    margin-top: 20px;
}
.superpositionVideo {
    width: 100%;
    border-radius: $border-radius-large;
}

.transitionTimeEnterActive,
.transitionTimeLeaveActive {
    transition: all 0.6s ease;
}
.transitionTimeEnter,
.transitionTimeLeaveTo {
    opacity: 0;
    transform: translateY(100%);
}
</style>
