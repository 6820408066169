import Vue from 'vue'

export const state = {
    errors: [],
    fields: {},
}

export const getters = {
    hasErrors: (state) => !!Object.keys(state.errors).length,
}

export const mutations = {
    SET_ERRORS(state, newErrors) {
        state.errors = newErrors
    },
    SET_FIELDS(state, newFields) {
        state.fields = newFields
    },
    UPDATE_FIELD(state, { name, value }) {
        state.fields[name] = value
    },
    REMOVE_ERROR(state, name) {
        Vue.delete(state.errors, name)
    },
    UPDATE_SUBMIT_IN_PROGRESS(state, newValue) {
        state.submitInProgress = newValue
    },
}

export const actions = {
    submit({ commit, state }, action) {
        return action(state.fields)
            .then((response) => response)
            .catch((error) => {
                if (error.response.status === 401) {
                    commit('SET_ERRORS', {
                        global: ['Mot de passe ou adresse e-mail incorrect.'],
                    })
                }
                if (error.response && error.response.status === 422) {
                    commit('SET_ERRORS', error.response.data.errors)
                }
                return Promise.reject(error)
            })
    },
    removeErrorIfExist({ commit, state }, name) {
        const index = Object.keys(state.errors).indexOf(name)
        if (index !== -1) {
            commit('REMOVE_ERROR', name)
        }
    },
}
