import http from '@state/http'
import { getState, saveState } from '@state/helpers'

const items = getState('items')

export const state = {
    products: [],
    items: items || [],
    customerInformations: getState('customerInformations') || {},
    customerShipping: getState('customerShipping') || {},
    checkoutStatus: null,
}

export const mutations = {
    pushProductToCart(state, { id, name }) {
        state.items.push({
            id,
            name,
            quantity: 1,
        })
    },
    sliceProductFromCart(state, { id }) {
        var index = state.items.map((x) => x.id).indexOf(id)
        state.items.splice(index, 1)
    },
    incrementItemQuantity(state, { id }) {
        const cartItem = state.items.find((item) => item.id === id)
        cartItem.quantity++
    },
    decrementItemQuantity(state, { id }) {
        const cartItem = state.items.find((item) => item.id === id)
        cartItem.quantity--
    },
    setCartItems(state, { items }) {
        state.items = items
    },
    setCustomerInformations(state, { customerInformations }) {
        state.customerInformations = customerInformations
    },
    setCustomerShipping(state, { customerShipping }) {
        state.customerShipping = customerShipping
    },
    setCheckoutStatus(state, status) {
        state.checkoutStatus = status
    },
    setProducts(state, newProducts) {
        state.products = newProducts
    },
    decrementProductInventory(state, { id }) {
        const product = state.products.find((product) => product.id === id)
        product.inventory--
    },
    incrementProductInventory(state, { id }) {
        const product = state.products.find((product) => product.id === id)
        product.inventory++
    },
}

export const getters = {
    cartProducts: (state, getters, rootState, rootGetters) => {
        if (!rootGetters['country/country']) return

        const productsPrices = rootGetters['country/country'].products

        if (!state.products.length) return

        return state.items.map(({ id, quantity }) => {
            const product = state.products.find((product) => product.id === id)
            if (product) {
                product.title = product.name.replace(/_/g, ' ')
                product.price = productsPrices[product.name].price
                product.quantity = quantity
            }

            return product
        })
    },
    shipping(state, getters, rootState, rootGetters) {
        if (!rootGetters['country/country']) return

        const shippingName = state.customerShipping.shipping || 'economy'
        let output = rootGetters['country/country'].shipping[shippingName]
        output.type = shippingName

        return output
    },
    cartTotalPrice: (state, getters) => {
        if (!getters.cartProducts) return

        return (
            getters.cartProducts.reduce((total, product) => {
                return total + product.price * product.quantity
            }, 0) + getters.shipping.price
        )
    },
    cartTotalCount: (state, getters) => {
        if (!getters.cartProducts) return

        return getters.cartProducts.reduce((total, product) => {
            return total + product.quantity
        }, 0)
    },
    productSensors: (state) => {
        return state.products.filter((product) => product.type === 1)
    },
    productMovePro: (state) => {
        return state.products.find((product) => product.name === 'move_pro')
    },
    productPolar: (state, getters, rootState, rootGetters) => {
        const polar = state.products.find((product) => product.name === 'polar')
        polar.price = rootGetters['country/country'].products['polar'].price

        return polar
    },
}

export const actions = {
    getProducts({ commit }) {
        return http
            .get('shop/products')
            .then((response) => {
                const products = response.data
                commit('setProducts', products)
            })
            .catch((error) => {
                console.error(error)
                commit('setProducts', [])
            })
    },
    addProductToCart({ state, commit }, product) {
        commit('setCheckoutStatus', null)
        if (product.inventory > 0 && product.status) {
            const cartItem = state.items.find((item) => item.id === product.id)
            if (cartItem) {
                commit('incrementItemQuantity', cartItem)
            } else {
                commit('pushProductToCart', { id: product.id, name: product.name })
            }
            commit('decrementProductInventory', { id: product.id })
            saveState('items', state.items)
        }
        window.fbq('track', 'AddToCart')
    },
    addPolar({ state, getters, dispatch }, fields) {
        if (fields.polar === 'with' && state.items.find((item) => item.name === 'polar') === undefined) {
            dispatch('addProductToCart', getters.productPolar)
        } else if (fields.polar === 'without' && state.items.find((item) => item.name === 'polar') !== undefined) {
            dispatch('removeProductFromCart', getters.productPolar)
        }
    },
    removeProductFromCart({ state, commit }, product) {
        commit('setCheckoutStatus', null)
        const cartItem = state.items.find((item) => item.id === product.id)
        if (cartItem.quantity === 1) {
            commit('sliceProductFromCart', { id: product.id })
        } else {
            commit('decrementItemQuantity', cartItem)
        }
        commit('incrementProductInventory', { id: product.id })
        saveState('items', state.items)
    },
    saveCustomerInformations({ commit }, fields) {
        return http.post('shop/customer', fields).then((response) => {
            commit('setCustomerInformations', { customerInformations: fields })
            saveState('customerInformations', fields)
        })
    },
    saveCustomerShipping({ commit }, fields) {
        return http.post('shop/shipping', fields).then((response) => {
            commit('setCustomerShipping', { customerShipping: fields })
            saveState('customerShipping', fields)
        })
    },
    checkout({ commit, state }, { token }) {
        const savedCartItems = [...state.items]

        commit('setCheckoutStatus', null)
        commit('setCartItems', { items: [] })
        return http
            .post('shop/checkout', {
                token,
                customerInformations: state.customerInformations,
                customerShipping: state.customerShipping,
                savedCartItems,
            })
            .then((response) => {
                commit('setCheckoutStatus', 'successful')
            })
            .catch(() => {
                commit('setCheckoutStatus', 'failed')
                commit('setCartItems', { items: savedCartItems })
            })
    },
}
