<script>
export default {
    props: {
        position: {
            type: String,
            default: 'left',
        },
    },
    computed: {
        positionStyle() {
            if (this.position === 'center') {
                return this.$style.titlePositionCenter
            } else if (this.position === 'right') {
                return this.$style.titlePositionRight
            }
            return false
        },
    },
}
</script>

<template>
    <h3 :class="[$style.typography, positionStyle]">
        <slot />
    </h3>
</template>

<style lang="scss" module>
@import '@design';

.typography {
    font-family: $font-family-heading;
    font-size: 47px;
    line-height: 1.085;
    font-weight: 700;
    letter-spacing: 0;
    @include mobile {
        font-size: 32px;
        line-height: 1.125;
        letter-spacing: 0.004em;
    }
    @include tablet-only {
        font-size: 40px;
        line-height: 1.1;
    }
}
.titlePositionCenter {
    text-align: center;
}
.titlePositionRight {
    text-align: right;
}
</style>
