export default [
    {
        path: '/',
        name: 'home',
        redirect: 'products',
    },
    {
        path: '/products',
        component: require('@views/products').default,
        children: [
            {
                path: '',
                name: 'products',
                component: require('@views/product-move').default,
            },
            {
                path: 'where-to-buy',
                name: 'where-to-buy',
                component: require('@views/where-to-buy').default,
            },
            {
                path: 'polar',
                name: 'polar',
                component: require('@views/product-polar').default,
            },
            {
                path: 'health',
                component: require('@views/product-health').default,
                children: [
                    {
                        path: '',
                        name: 'health',
                        component: require('@views/product-move-health').default,
                    },
                    {
                        path: 'horsetrack',
                        name: 'horsetrack',
                        component: require('@views/product-move-horsetrack').default,
                    },
                ],
            },
        ],
    },
]
