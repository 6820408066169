<script>
import { mapGetters, mapActions } from 'vuex'
import { i18n } from '@src/i18n'

export default {
    page: {
        titleTemplate() {
            return 'Alogo'
        },
    },
    computed: {
        ...mapGetters('country', ['country']),
        areTranslationLoaded() {
            const messages = i18n.messages?.[i18n.locale]
            if (!messages) return false
            else return Object.values(messages)?.length !== 0
        },
    },
    created() {
        this.getCountries().then(() => {
            this.getVisitorCountry()
            this.getProducts()
        })
    },
    methods: {
        ...mapActions('country', ['getCountries', 'getVisitorCountry']),
        ...mapActions('cart', ['getProducts']),
    },
}
</script>

<template>
    <div v-if="!country || !areTranslationLoaded" :class="$style.loading">
        <BaseLogo color="#fff" :class="$style.logo" />
        <BaseSpinner />
    </div>
    <RouterView v-else :class="[$style.app, $route.name]" />
</template>

<style lang="scss">
@import '@src/design/global.scss';
</style>

<style lang="scss" module>
@import '@design';

.app {
    flex-grow: 1;
}
.loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}
.logo {
    height: 50px;
    margin-top: -90px;
    opacity: 0.8;
}
</style>
