import Vue from 'vue'
import VueI18n from 'vue-i18n'
import getBrowserLocale from '@utils/i18n/get-browser-locale'
import { supportedLocalesInclude } from '@utils/i18n/supported-locales'

Vue.use(VueI18n)

const getStartingLocale = () => {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true })

    if (supportedLocalesInclude(browserLocale)) {
        return browserLocale
    } else {
        return process.env.VUE_APP_LOCAL || 'en'
    }
}

export const i18n = new VueI18n({
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_LOCAL,
    messages: null,
})

export const loadLocaleMessages = async (locale) => {
    await setI18nLanguage(locale)
    const messages = await import(`./locales/${locale}.json`)
    i18n.setLocaleMessage(locale, messages)
}

const setI18nLanguage = (locale) => {
    i18n.locale = locale
    document.querySelector('html').setAttribute('lang', locale)

    return locale
}

loadLocaleMessages(i18n.locale)

export default i18n
