import Vue from 'vue'
import http from '@state/http'
import router from '@router'

export const state = {
    epreuve: null,
    passedStart: false,
    passedFinish: false,
    passedObstacles: null,
}

export const mutations = {
    RESET_ALL_PASSED(state) {
        state.passedStart = false
        state.passedStart = false
        state.passedObstacles = null
    },
    SET_PASSED_START(state, value) {
        state.passedStart = value
    },
    SET_PASSED_FINISH(state, value) {
        state.passedFinish = value
    },
    SET_EPREUVE(state, newEpreuve) {
        state.epreuve = newEpreuve
    },
    SET_IN_PROGRESS(state, newId) {
        Vue.set(state.epreuve, 'in_progress', newId)
    },
    SET_COMPETITORS(state, newCompetitors) {
        Vue.set(state.epreuve, 'competitors', newCompetitors)
    },
    SET_OBSTACLE_STATUS(state, { id, newValue }) {
        let index = state.obstacles.map((x) => x.id).indexOf(id)
        Vue.set(state.obstacles[index], 'status', newValue)
    },
}

export const getters = {
    competitorsNext(state) {
        if (state.epreuve) {
            return state.epreuve.competitors.filter((competitor) => {
                return (
                    !competitor.rounds[state.epreuve.round - 1].time &&
                    competitor.rounds[state.epreuve.round - 1].number !== state.epreuve.in_progress
                )
            })
        }
    },
    competitorInProgress(state) {
        if (state.epreuve) {
            const competitor = state.epreuve.competitors.filter((competitor) => {
                return competitor.rounds[state.epreuve.round - 1].number === state.epreuve.in_progress
            })
            return typeof competitor[0] !== 'undefined' ? competitor[0] : null
        }
    },
    competitorsDone(state) {
        if (state.epreuve) {
            return state.epreuve.competitors.filter((competitor) => {
                return competitor.rounds[state.epreuve.round - 1].time
            })
        }
    },
    pathPassed(state) {
        if (state.epreuve) {
            return state.epreuve.obstacles.path.filter((obstacle, index, obstacles) => {
                const previous = obstacles[index - 1]
                return obstacle.status !== null || (typeof previous !== 'undefined' && previous.status !== null)
            })
        }
    },
}

export const actions = {
    fetchEpreuve({ commit }) {
        return http.get('broadcast/epreuve').then((response) => {
            const epreuve = response.data
            commit('SET_EPREUVE', epreuve)
            return epreuve
        })
    },
    general({ dispatch }) {
        window.Echo.channel('general')
            .listen('NewEpreuveBecomeLive', (notification) => {
                if (router.currentRoute.name !== 'broadcast') {
                    if (router.currentRoute.name === 'broadcast-nolive') {
                        setTimeout(() => router.push({ name: 'broadcast' }), 1000)
                        notification.timeout = 4000
                    } else {
                        notification.button = {
                            name: 'Voir',
                            to: 'broadcast',
                        }
                    }
                    dispatch('notifications/addNotification', notification, {
                        root: true,
                    })
                }
            })
            .listen('NewNotification', (notification) => {
                dispatch('notifications/addNotification', notification, { root: true })
            })
    },
    broadcaster({ commit, dispatch }) {
        window.Echo.channel('broadcaster')
            .listen('SetNextCompetitor', (competitors) => {
                commit('RESET_ALL_PASSED')
                commit('SET_COMPETITORS', competitors)
            })
            .listen('CellDetection', ({ channel, time }) => {
                if (channel === 'C0') {
                    this.currentRiderStart(time)
                }
                if (channel === 'C1') {
                    this.currentRiderFinish(time)
                }
            })
            .listen('SetBoardPassed', (board) => {
                if (board === 'start') {
                    commit('SET_PASSED_START', true)
                }
                if (board === 'finish') {
                    commit('SET_PASSED_FINISH', true)
                }
            })
            .listen('NewInProgress', (id) => {
                commit('SET_IN_PROGRESS', id)
            })
    },
}
