<script>
import design from '@design'

export default {
    data() {
        return {
            badgeColor: design.globalColor,
        }
    },
}
</script>

<template>
    <div>
        <BaseSection>
            <BaseHeroTitles :title="$t('products.move.health.headline')" />
            <BaseSectionSubsection>
                <BaseSubsectionWrapper position="left">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.health.subpages.follow-up.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.intro">
                            <div
                                v-for="(intro, index) in $t('products.move.health.subpages.follow-up.intro')"
                                :key="'intro-follow-up-' + index"
                            >
                                {{ intro }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/app-mockup-calendar.png" :class="[$style.rounded, $style.w100]" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>

            <BaseSectionSubsection>
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseBadge
                            :text="$t('products.move.health.subpages.rehabilitation.badge').toUpperCase()"
                            :light="true"
                            :class="$style.premiumBadge"
                            :color="$style.globalSubscriptionColor"
                        />
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.health.subpages.rehabilitation.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.intro">
                            <div
                                v-for="(intro, index) in $t('products.move.health.subpages.rehabilitation.intro')"
                                :key="'intro-rehabilitation-' + index"
                            >
                                {{ intro }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/rehabilitation.png" :class="[$style.rounded, $style.w100]" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>

            <BaseSectionSubsection>
                <BaseSubsectionWrapper position="left">
                    <BaseSectionParagraph size="half">
                        <BaseBadge
                            :text="$t('products.move.health.subpages.irregularity.badge').toUpperCase()"
                            :light="true"
                            :class="$style.premiumBadge"
                            :color="badgeColor"
                        />
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.health.subpages.irregularity.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.intro">
                            <div
                                v-for="(intro, index) in $t('products.move.health.subpages.irregularity.intro')"
                                :key="'intro-irregularity-' + index"
                            >
                                {{ intro }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>

                        <BaseTypographyOverviewSectionIntro>
                            <BaseLink :class="$style.horsetrackLink" :to="{ name: 'horsetrack' }">
                                {{ $t('products.move.hero.horsetrack') }}
                            </BaseLink>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.technologyInjuries">
                        <img src="@assets/images/app-mockup-injuries.png" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.intro {
    * > * & :not(:last-child) {
        margin-bottom: 30px;
    }
}

.rounded {
    border-radius: $border-radius-large;
}

.w-100 {
    width: 100%;
}

.health-buttons {
    * > * & :first-child {
        margin-right: 10px;
    }
}

.premiumBadge {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 0px;
}

.technologyInjuries {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 540px;

        @include mobile {
            height: 380px;
        }
    }
}
</style>
