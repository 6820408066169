import http from '@state/http'

export const state = {
    competitions: [],
}

export const mutations = {
    SET_COMPETITIONS(state, newCompetitions) {
        state.competitions = newCompetitions
    },
}

export const getters = {
    competitions: (state) => state.competitions,
}

export const actions = {
    fetchCompetitions({ commit, state, dispatch }) {
        return http.get('/api/competitions').then((response) => {
            const competitions = response.data
            commit('SET_COMPETITIONS', competitions)
            return competitions
        })
    },
}
