<script>
import HeaderLayout from './main-header'
import FooterLayout from './main-footer'
import ModalSessionDemo from '@components/modal-session-demo'

export default {
    name: 'MainLayout',
    components: {
        HeaderLayout,
        FooterLayout,
        ModalSessionDemo,
    },
}
</script>

<template>
    <div :class="$style.layout">
        <HeaderLayout />
        <main :class="$style.main">
            <slot />
        </main>
        <FooterLayout />
        <BaseModal>
            <ModalSessionDemo />
        </BaseModal>
    </div>
</template>

<style lang="scss" module>
.layout {
    flex: 1;
}
.main {
    position: relative;
    padding-top: 90px;
}
</style>
