<script>
import { mapState, mapMutations } from 'vuex'

export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            component: null,
            hasFocus: false,
        }
    },
    computed: {
        ...mapState('form', {
            errors: (state) => state.errors,
            fields: (state) => state.fields,
        }),
        loader() {
            if (this.field.type === 'text' || this.field.type === 'email') {
                return () => import(`@components/form-input`)
            } else {
                return () => import(`@components/form-${this.field.type}`)
            }
        },
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@components/form-input')
            })
    },
    methods: {
        ...mapMutations('form', ['UPDATE_FIELD']),
    },
}
</script>

<template>
    <component
        :is="component"
        v-if="component"
        :field="field"
        :value="fields[field.name]"
        class="fieldWrapper"
        :class="{
            hasFocus: hasFocus,
            hasValue: fields[field.name],
            hasError: errors[field.name],
        }"
        @input="
            UPDATE_FIELD({
                name: field.name,
                value: $event,
            })
        "
        @focus="hasFocus = true"
        @blur="hasFocus = false"
    />
</template>
