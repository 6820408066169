export const state = {
    open: false,
    pageYOffset: null,
}

export const mutations = {
    SET_OPEN(state, newValue) {
        state.open = newValue
    },
    SET_PAGEYOFFSET(state, newValue) {
        state.pageYOffset = newValue
    },
}

export const getters = {}

export const actions = {
    toggleModal({ commit, dispatch, state }, country) {
        dispatch('fixBodyForModal').then(() => {
            commit('SET_OPEN', !state.open)
        })
    },
    fixBodyForModal({ commit, state }) {
        return new Promise((resolve, reject) => {
            const element = document.body
            if (!state.open) {
                commit('SET_PAGEYOFFSET', window.pageYOffset)
                Object.assign(element.style, {
                    position: 'fixed',
                    overflow: 'hidden',
                    top: `-${state.pageYOffset}px`,
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                })
            } else {
                element.removeAttribute('style')
                window.scrollTo(0, state.pageYOffset)
            }
            resolve()
        })
    },
}
