export default [
    {
        path: '/broadcast',
        component: require('@views/broadcast').default,
        children: [
            {
                path: '',
                name: 'broadcast',
                component: require('@views/broadcast').default,
            },
            {
                path: 'map',
                name: 'broadcast-map',
                component: require('@views/broadcast-map').default,
            },
            {
                path: 'nolive',
                name: 'broadcast-nolive',
                component: require('@views/broadcast-nolive').default,
            },
            {
                path: 'ranking',
                name: 'broadcast-ranking',
                component: require('@views/broadcast-ranking').default,
            },
            {
                path: 'competitors',
                name: 'broadcast-competitors',
                component: require('@views/broadcast-competitors').default,
            },
        ],
    },
]
