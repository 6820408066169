<script>
import Layout from '@layouts/main'

export default {
    page: {
        title: "L'entreprise",
    },
    components: {
        Layout,
    },
}
</script>

<template>
    <Layout>
        <RouterView />
    </Layout>
</template>
