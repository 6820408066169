<script>
import { capitalize } from '@src/filters'

export default {
    props: {
        size: {
            type: String,
            default: 'half',
            validator(value) {
                return ['full', 'big', 'half', 'third'].indexOf(value) !== -1
            },
        },
        position: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'center', 'right'].indexOf(value) !== -1
            },
        },
    },
    computed: {
        sizeStyle() {
            return this.$style[`size${capitalize(this.size)}`]
        },
        positionStyle() {
            return this.$style[`position${capitalize(this.position)}`]
        },
    },
}
</script>

<template>
    <div :class="[$style.paragraph, sizeStyle, positionStyle]">
        <slot />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.paragraph {
    flex: none;
    position: relative;
    z-index: 1;
}
.sizeFull {
    width: 100%;
}
.sizeBig {
    width: 75%;

    @include mobile {
        width: 100%;
    }
    @include tablet-only {
        width: 83.33333%;
    }
}
.sizeHalf {
    width: 42%;

    @include mobile {
        width: 100%;
    }
}
.sizeThird {
    width: 58%;
}
.positionLeft {
    text-align: left;
}
.positionCenter {
    text-align: center;
}
.positionRight {
    text-align: right;
}
</style>
