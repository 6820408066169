<script>
export default {
    props: {
        position: {
            type: Object,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
}
</script>

<template>
    <div
        :class="$style.background"
        :style="{
            backgroundImage:
                'radial-gradient(ellipse at ' + position.x + '%' + position.y + '%' + ', ' + color + ' 0%, #000 80%)',
        }"
    >
    </div>
</template>

<style lang="scss" module>
.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    min-height: auto;
    overflow-x: hidden;
    background-color: #000;
    transform: skewY(-12deg);
}
</style>
