import Vue from 'vue'
import router from '@router'

export const state = {
    notifications: [],
}

export const mutations = {
    SET_NOTIFICATION(state, newNotification) {
        state.notifications.push(newNotification)
    },
    SET_NOTIFICATION_READED(state, id) {
        let index = state.notifications.map((x) => x.id).indexOf(id)
        Vue.set(state.notifications[index], 'read', true)
    },
}

export const getters = {
    unreadNotifications(state) {
        return state.notifications.filter((notification, index, notifications) => {
            return !notification.read
        })
    },
}

export const actions = {
    addNotification({ commit }, notification) {
        commit('SET_NOTIFICATION', notification)
        if (notification.timeout) {
            setTimeout(() => {
                commit('SET_NOTIFICATION_READED', notification.id)
            }, notification.timeout)
        }
    },
    closeNotification({ commit }, id) {
        commit('SET_NOTIFICATION_READED', id)
    },
    linkNotification({ commit }, notification) {
        router.push({ name: notification.button.to })
        setTimeout(() => commit('SET_NOTIFICATION_READED', notification.id), 100)
    },
}
