<script>
import backgroundColor from '@components/mixins/backgroundColor'

export default {
    mixins: [backgroundColor],
}
</script>

<template>
    <header :class="[$style.header, backgroundColorClass]">
        <div class="container">
            <div :class="$style.headerContent">
                <h1 class="commonPageTitle" :class="$style.headerTitle">
                    <slot name="title" />
                </h1>
                <p class="commonIntroText" :class="$style.headerIntro">
                    <slot name="intro" />
                </p>
            </div>
        </div>
    </header>
</template>

<style lang="scss" module>
@import '@design';

.header {
    position: relative;
    margin-top: -90px;
    padding-top: 90px;
    background: linear-gradient(#1a1a1f, black);
}
.headerContent {
    max-width: 720px;
    padding: 100px 0 70px;
    margin: 0 auto;
    text-align: center;

    @include tablet {
        padding: 160px 0 140px;
    }
}
.headerTitle {
    margin: 0 0 20px;
    font-weight: 500;
}
</style>
