<template>
    <div ref="playerDOM" class="plyr__video-embed">
        <iframe :src="cleanSrc" />
    </div>
</template>

<script>
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export default {
    props: {
        src: {
            type: String,
            required: true,
        },
        options: {
            type: Object,
            required: false,
            default: () => {
                return {}
            },
        },
    },
    data() {
        return {
            player: undefined,
        }
    },
    computed: {
        opts: function () {
            return {
                // ...{
                //     debug: true,
                //     loadSprite: false,
                //     iconUrl: '/images/icons/plyr.svg',
                //     hideControls: false,
                //     resetOnEnd: true,
                //     loop: { active: true },
                //     fullscreen: { enabled: true, fallback: true, iosNative: true, container: null }, //solve the bug https://github.com/Alogo-Analysis/mobile-app/issues/421
                // },
                // ...this.options,
            }
        },
        cleanSrc: function () {
            // console.log('track cleanSrc')
            return this.src ? this.src.replace('watch?v=', 'embed/') : ''
        },
    },
    mounted() {
        // console.log('track onMounted')
        this.player = new Plyr(this.$refs.playerDOM, this.opts.value)
    },
    beforeUnmount() {
        this.player.destroy()
    },
    setup(props) {},
}
</script>
