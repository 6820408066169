import http from '@state/http'
import { getState, saveState, removeState } from '@state/helpers'

export const state = {
    accessToken: getState('auth.accessToken'),
    refreshToken: getState('auth.refreshToken'),
    currentUser: getState('auth.currentUser'),
}

export const mutations = {
    SET_ACCESS_TOKEN(state, newValue) {
        state.accessToken = newValue
        if (newValue) {
            saveState('auth.accessToken', newValue)
        } else {
            removeState('auth.accessToken')
        }
    },
    SET_REFRESH_TOKEN(state, newValue) {
        state.refreshToken = newValue
        if (newValue) {
            saveState('auth.refreshToken', newValue)
        } else {
            removeState('auth.refreshToken')
        }
    },
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('auth.currentUser', newValue)
    },
}

export const getters = {
    loggedIn(state) {
        return !!state.currentUser
    },
}

export const actions = {
    signUp({ commit, dispatch }, { email, password } = {}) {
        return http.post('/api/auth/signup', { email, password }).then((response) => {
            const tokens = response.data
            commit('SET_ACCESS_TOKEN', tokens.access_token)
            commit('SET_REFRESH_TOKEN', tokens.refresh_token)
            const user = dispatch('fetchCurrentUser')
            return user
        })
    },

    logIn({ commit, dispatch }, { email, password } = {}) {
        return http.post('/api/auth/login', { email, password }).then((response) => {
            const tokens = response.data
            commit('SET_ACCESS_TOKEN', tokens.access_token)
            commit('SET_REFRESH_TOKEN', tokens.refresh_token)
            const user = dispatch('fetchCurrentUser')
            return user
        })
    },

    logOut({ commit }) {
        http.get('/api/auth/logout').then(() => {
            commit('SET_ACCESS_TOKEN', null)
            commit('SET_REFRESH_TOKEN', null)
            commit('SET_CURRENT_USER', null)
        })
    },

    fetchCurrentUser({ commit, state, dispatch }) {
        return http.get('/api/auth/currentuser').then((response) => {
            const user = response.data
            commit('SET_CURRENT_USER', user)
            return user
        })
    },

    refreshToken({ commit, state }) {
        return http
            .post('/api/auth/refresh', { refresh_token: state.refreshToken })
            .then((response) => {
                const tokens = response.data
                commit('SET_ACCESS_TOKEN', tokens.access_token)
                commit('SET_REFRESH_TOKEN', tokens.refresh_token)
                return tokens
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    commit('SET_ACCESS_TOKEN', null)
                    commit('SET_REFRESH_TOKEN', null)
                    commit('SET_CURRENT_USER', null)
                }
                return null
            })
    },
}
