import { mapState, mapGetters, mapActions } from 'vuex'

// Auth
export const authComputed = {
    ...mapState('auth', {
        currentUser: (state) => state.currentUser,
    }),
    ...mapGetters('auth', ['loggedIn']),
}
export const authMethods = mapActions('auth', ['signUp', 'logIn', 'logOut'])

// Broadcast
export const broadcastComputed = {
    ...mapState('broadcast', {
        epreuve: (state) => state.epreuve,
    }),
    ...mapGetters('broadcast', ['competitorsNext', 'competitorInProgress', 'competitorsDone', 'pathPassed']),
}
export const broadcastMethods = {
    ...mapActions('broadcast', ['fetchEpreuve']),
}

// Competitions
export const competitions = {
    ...mapGetters('competitions', ['competitions']),
}

// sessionStorage
export function getState(key) {
    return JSON.parse(window.sessionStorage.getItem(key))
}
export function saveState(key, state) {
    window.sessionStorage.setItem(key, JSON.stringify(state))
}
export function removeState(key) {
    window.sessionStorage.removeItem(key)
}

// Private Helper
export function clone(value) {
    return JSON.parse(JSON.stringify(value))
}
export function ucfirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}
export function transformTimeInSeconde(time) {
    return time / 100
}
