<script>
import { capitalize } from '@src/filters'

export default {
    props: {
        type: {
            type: String,
            default: 'side',
            validator(value) {
                return ['side', 'full'].indexOf(value) !== -1
            },
        },
        position: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'center', 'right'].indexOf(value) !== -1
            },
        },
    },
    computed: {
        typeStyle() {
            return this.$style[`type${capitalize(this.type)}`]
        },
        positionStyle() {
            return this.$style[`position${capitalize(this.position)}`]
        },
    },
}
</script>

<template>
    <div :class="[$style.wrapper, typeStyle, positionStyle]">
        <slot />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: relative;
    z-index: 1;

    @include tablet {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
.wrapper + .wrapper {
    margin-top: 100px;

    @include tablet {
        margin-top: 175px;
    }
}
.typeSide {
    flex-direction: row;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
    }
}
.typeSide > div:last-child {
    @include mobile {
        margin-top: 55px;
    }
}
.positionRight {
    flex-direction: row-reverse;
}
.positionLeft > div:first-child {
    @include tablet {
        margin-right: 55px;
    }
}
.positionRight > div:first-child {
    @include tablet {
        margin-left: 55px;
    }
}
.positionCenter > div:last-child {
    margin-top: 35px;

    @include tablet {
        margin-top: 55px;
    }
}
</style>
