<script>
export default {
    page: {
        title: 'HorseTrack',
    },
    data() {
        return {}
    },
}
</script>

<template>
    <div>
        <!-- Hero -->
        <BaseSection :hero="true">
            <BaseHeroTitles :title="$t('products.move.heart-rate.headline')" />
            <BaseSectionSubsection>
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.spaceY">
                            <div
                                v-for="(text, index) in $t(
                                    'products.move.heart-rate.subpages.monitor-heart-rate.intro'
                                )"
                                :key="'heart-rate-intro' + index"
                            >
                                {{ text }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <BaseSectionMedia style="justify" :class="$style.polarLogoAndEquine">
                            <div :class="$style.poweredByPolar">
                                <div>Powered by</div>
                                <img :src="require(`@assets/images/Polar/logo.svg`)" :class="$style.polarLogo" />
                            </div>
                            <img :src="require(`@assets/images/Polar/equine.png`)" :class="$style.polarEquine" />
                        </BaseSectionMedia>
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- What it is -->
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subpages.what-it-is.headline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.spaceY">
                            <div
                                v-for="(text, index) in $t('products.move.heart-rate.subpages.what-it-is.intro')"
                                :key="'what-it-is-intro' + index"
                            >
                                {{ text }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/Polar/equine-on-horse.jpg" :class="[$style.w100, $style.rounded]" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- What it does -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subpages.what-it-does.headline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.spaceY">
                            <div
                                v-for="(text, index) in $t('products.move.heart-rate.subpages.what-it-does.intro')"
                                :key="'what-it-does-intro' + index"
                            >
                                {{ text }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/webapp-mockup-heart-rate.png" :class="$style.mockupWebHeartRate" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- Don't have yet -->
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subpages.dont-have-polar-yet.headline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.spaceY">
                            <div
                                v-for="(text, index) in $t(
                                    'products.move.heart-rate.subpages.dont-have-polar-yet.intro'
                                )"
                                :key="'dont-have-polar-yet-intro' + index"
                            >
                                {{ text }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/images/Polar/equine-in-hands.jpg" :class="$style.equineInHands" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- Already have -->
                <BaseSubsectionWrapper type="full" position="center">
                    <BaseSectionParagraph position="center" size="big">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subpages.already-have-polar.headline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <ul :class="$style.stepList">
                            <li
                                v-for="(step, indexStep) in $t(
                                    'products.move.heart-rate.subpages.already-have-polar.steps'
                                )"
                                :key="'already-have-polar-step' + indexStep"
                            >
                                <img :src="require(`@assets/${step.image}`)" />

                                <p>
                                    {{ step.text }}
                                </p>
                            </li>
                        </ul>
                    </BaseSectionMedia>

                    <BaseTypographyOverviewSectionIntro position="center">
                        <span>{{ $t('products.move.heart-rate.subpages.already-have-polar.doc.before-link') }}</span>
                        <BaseLink href="https://www.youtube.com/watch?v=QRYwVFPRSfs&ab_channel=Polar"
                            ><span>{{
                                $t('products.move.heart-rate.subpages.already-have-polar.doc.link')
                            }}</span></BaseLink
                        >
                        <span>{{ $t('products.move.heart-rate.subpages.already-have-polar.doc.after-link') }}</span>
                    </BaseTypographyOverviewSectionIntro>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.spaceY {
    * > * & :not(:first-child) {
        margin-top: 30px;
    }
}

.rounded {
    border-radius: $border-radius-large;
}

.w-100 {
    width: 100%;
}

.polarLogoAndEquine {
    display: grid;
    justify-content: center;
    justify-items: center;

    * > * & :not(:first-child) {
        margin-top: 50px;
    }

    .poweredByPolar {
        display: inherit;
        justify-content: inherit;
        justify-items: inherit;

        * > * & :not(:first-child) {
            margin-top: 5px;
        }

        .polarLogo {
            width: 200%;
        }
    }
}

.mockupWebHeartRate,
.equineInHands {
    width: 100%;
    border-radius: $border-radius-large;
}

.stepList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-top: 30px;

    @include mobile {
        li:not(:first-child) {
            margin-top: 30px;
        }
    }

    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        width: 300px;
        height: 630px;
        padding: 50px;
        margin: 10px;
        background: $color-background-dark;
        border-radius: 50px;

        img {
            width: 100%;
        }

        p {
            margin-top: 15px;
            text-align: center;
        }
    }
}
</style>
