const mixin = {
    data() {
        return {
            reveal: false,
        }
    },
    methods: {
        visibilityChanged(isVisible, entry) {
            this.reveal = isVisible
        },
    },
}

export default mixin
