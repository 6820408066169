<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
}
</script>

<template>
    <label class="label" :for="name" :class="$style.label">
        {{ label }}
    </label>
</template>

<style lang="scss" module>
@import '@design';

.label {
    @include unselectable;
    @include taphighlight;

    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 1;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 0.95em;
    color: #737373;
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: text;
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1),
        font-size 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
</style>
