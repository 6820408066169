<script>
// Todo : gérer proprement : afficher date début - date fin si différente
// Todo: faire une fonction que génère la période comme dans l'App

import { mapGetters } from 'vuex'
import { ucfirst } from '@state/helpers'
import design from '@design'

export default {
    computed: {
        ...mapGetters('country', ['country']),
    },
    methods: {
        uc_first(string) {
            return ucfirst(string)
        },
    },
}
</script>

<template>
    <div
        v-if="country.products.move_pro.event"
        :class="$style.wrapper"
        :style="{
            color: country.products.move_pro.event.color,
        }"
    >
        <div :class="$style.content">
            <div :class="$style.date">
                {{
                    $t(`products.move.discount.until`, {
                        date: uc_first(
                            new Date(country.products.move_pro.event.end_at).toLocaleDateString($i18n.locale, {
                                weekday: undefined,
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric',
                            })
                        ),
                    })
                }}
            </div>
            <div>
                <h2 :class="$style.headline">
                    {{ $t(`products.move.discount.${country.products.move_pro.event.key}`) }}
                </h2>
                <!-- <pre>{{ country }}</pre> -->
            </div>
        </div>

        <div :class="$style.right">
            <div
                :class="$style.rightText"
                :style="{
                    'background-color': country.products.move_pro.event.color,
                }"
            >
                {{ $t(`products.move.discount.${country.products.move_pro.event.key}-right`) }}
            </div>
            <div
                v-if="country.products.move_pro.event.asterisk"
                :class="$style.asterisk"
                :style="{
                    color: country.products.move_pro.event.color,
                }"
            >
                {{ $t(`products.move.discount.${country.products.move_pro.event.key}-asterisk`) }}
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    background-color: transparent;
    display: flex;
    justify-content: center;
}

.content {
    width: 692px;
    width: fit-content;
}

.headline {
    font-size: 12pt;
    font-weight: 800;
    line-height: 17pt;
    text-transform: uppercase;
}

.date {
    font-size: 13pt;
    line-height: 18pt;
    text-align: end;
    white-space: nowrap;
}

.right {
    align-self: center;
    margin-left: 8px;
    white-space: nowrap;
}
.rightText {
    padding: 7px 15px;
    font-size: 12pt;
    line-height: 15pt;
    font-weight: 800;
    color: $color-text;
    border-radius: $border-radius;
}

.asterisk {
    align-self: center;
    font-style: italic;
    font-size: 6pt;
    line-height: 7pt;
}
</style>
