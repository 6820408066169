<script>
import { capitalize } from '@src/filters'

export default {
    props: {
        type: {
            type: String,
            default: 'simple',
            validator(value) {
                return ['simple', 'overlay'].indexOf(value) !== -1
            },
        },
    },
    computed: {
        typeStyle() {
            return this.$style[`type${capitalize(this.type)}`]
        },
    },
}
</script>

<template>
    <div :class="[$style.media, typeStyle]">
        <slot />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.typeSimple {
    width: 100%;
}
.typeOverlay {
    img {
        width: 100%;
        @include tablet {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
}
</style>
