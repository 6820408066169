<script>
export default {
    props: {
        start: {
            type: Object,
            required: true,
        },
        finish: {
            type: Object,
            required: true,
        },
        obstacles: {
            type: Array,
            required: true,
        },
        path: {
            type: Array,
            required: true,
        },
        pathPassed: {
            type: Array,
            required: true,
        },
    },
    computed: {
        svgPath() {
            return this.path.reduce(
                (acc, path, i, paths) =>
                    i === 0
                        ? `M ${path.position.x},${path.position.y}`
                        : `${acc} ${this.bezierCommand(path, i, paths, true)}`,
                ''
            )
        },
        svgPathPassed() {
            return this.pathPassed.reduce(
                (acc, path, i, paths) =>
                    i === 0
                        ? `M ${path.position.x},${path.position.y}`
                        : `${acc} ${this.bezierCommand(path, i, paths, false)}`,
                ''
            )
        },
    },
    mounted() {
        this.setDashoffset(this.$refs.pathPassed)
    },
    methods: {
        bezierCommand(path, i, paths, mainPath) {
            const cps = this.controlPath(paths[i - 1], paths[i - 2], path)
            const cpe = this.controlPath(path, paths[i - 1], paths[i + 1], true)
            const close = i === paths.length - 1 ? ' ' : ''
            if (path.status !== null || mainPath) {
                return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${path.position.x},${path.position.y}${close}`
            } else {
                return `${close}`
            }
        },
        controlPath(current, previous, next, reverse) {
            const pr = previous || current
            const ne = next || current
            const p = pr.position
            const n = ne.position
            const smoothing = 0.2
            const o = this.line(p, n)
            const angle = o.angle + (reverse ? Math.PI : 0)
            const length = o.length * smoothing
            const x = current.position.x + Math.cos(angle) * length
            const y = current.position.y + Math.sin(angle) * length
            return [x, y]
        },
        line(pointA, pointB) {
            const lengthX = pointB.x - pointA.x
            const lengthY = pointB.y - pointA.y
            return {
                length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
                angle: Math.atan2(lengthY, lengthX),
            }
        },
        setDashoffset(el) {
            const isLine = el instanceof SVGLineElement
            const pathLength = isLine
                ? this.dist(el.x1.baseVal.value, el.x2.baseVal.value, el.y1.baseVal.value, el.y2.baseVal.value)
                : el.getTotalLength()
            el.style['stroke-dasharray'] = pathLength
            el.style['stroke-dashoffset'] = `-${pathLength}`
            return pathLength
        },
        dist(x1, x2, y1, y2) {
            return Math.sqrt((x2 -= x1) * x2 + (y2 -= y1) * y2)
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <!-- Board START -->
        <div
            v-if="start"
            :class="$style.board"
            :style="[
                {
                    left: `${start.position.x}%`,
                    top: `${start.position.y}%`,
                },
            ]"
        >
            {{ start.name }}
        </div>

        <!-- Board FINISH -->
        <div
            v-if="finish"
            :class="[$style.board, $style.boardFinish]"
            :style="[
                {
                    left: `${finish.position.x}%`,
                    top: `${finish.position.y}%`,
                },
            ]"
        >
            {{ finish.name }}
        </div>

        <!-- Obstacles Numbers -->
        <div
            v-for="(obstacle, key) in obstacles"
            :key="`obstacleNumber${key}`"
            :class="$style.obstacleNumber"
            :style="{
                left: `${obstacle.position.x}%`,
                top: `${obstacle.position.y}%`,
            }"
        >
            {{ obstacle.name }}
        </div>

        <!-- Obstacles -->
        <div
            v-for="(obstacle, key) in obstacles"
            :key="key"
            :class="$style.obstacles"
            :style="{
                left: `${obstacle.position.x}%`,
                top: `${obstacle.position.y}%`,
                transform: `translate(-50%, -50%) rotate(${obstacle.position.r}deg)`,
            }"
        >
            <!-- 7 Vertical -->
            <div v-if="obstacle.type === 7" :class="[$style.obstacle, $style.vertical]">
                <div :class="[$style.jump, $style.barrage]" />
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
            <!-- 2 Oxer -->
            <div v-if="obstacle.type === 2" :class="[$style.obstacle, $style.oxer]">
                <div :class="$style.jump">
                    <div :class="$style.barrage" />
                    <div :class="$style.barrage" />
                </div>
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
            <!-- 3 Oxer Polonais -->
            <div v-if="obstacle.type === 3" :class="[$style.obstacle, $style.oxerPolonais]">
                <div :class="$style.jump">
                    <div :class="$style.barrage" />
                    <div :class="$style.barrage" />
                </div>
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
            <!-- 6 Triple -->
            <div v-if="obstacle.type === 6" :class="[$style.obstacle, $style.triple]">
                <div :class="$style.jump">
                    <div :class="$style.barrage" />
                    <div :class="$style.barrage" />
                    <div :class="$style.barrage" />
                </div>
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
            <!-- 5 River -->
            <div v-if="obstacle.type === 5" :class="[$style.obstacle, $style.river]">
                <div :class="[$style.watter, $style.barrage]" />
            </div>
            <!-- 4 Palanques -->
            <div v-if="obstacle.type === 4" :class="[$style.obstacle, $style.palanques]">
                <div :class="[$style.jump, $style.barrage]" />
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
            <!-- 1 Wall -->
            <div v-if="obstacle.type === 1" :class="[$style.obstacle, $style.wall]">
                <div :class="[$style.jump, $style.barrage]" />
                <div v-if="obstacle.watter" :class="$style.watter" />
            </div>
        </div>

        <!-- Path -->
        <svg v-if="path" :class="$style.svg" viewBox="0 0 100 100" preserveAspectRatio="none">
            <defs>
                <linearGradient id="gradientPathPassed" x1="0%" y1="0%" y2="0%">
                    <stop offset="33%" stop-color="#c4987a" />
                    <stop offset="73%" stop-color="#ffd5bf" />
                </linearGradient>
            </defs>
            <path :class="$style.path" :d="svgPath" />
            <path ref="pathPassed" :class="$style.pathPassed" :d="svgPathPassed" />
        </svg>

        <!-- Path Points -->
        <svg ref="svg" :class="$style.svg">
            <g :class="$style.svgPath">
                <circle r="8" :class="$style.pathPoint" :cx="path[0].position.x + '%'" :cy="path[0].position.y + '%'" />
                <circle
                    r="8"
                    :class="$style.pathPoint"
                    :cx="path[path.length - 1].position.x + '%'"
                    :cy="path[path.length - 1].position.y + '%'"
                />
            </g>
        </svg>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: relative;
    height: 368px;
}

.svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 368px;
}
.svgPath {
    circle {
        fill: #3e3e3e;
        stroke: #121216;
        stroke-width: 3;
        animation: circleBorn 0.3s ease-in-out;
    }
}

@keyframes circleBorn {
    0% {
        r: 0;
    }
    70% {
        r: 17;
    }
    100% {
        r: 13;
    }
}

// Path
.path,
.pathPassed {
    fill: none;
    stroke-width: 1;
}
.path {
    stroke: #3e3e3e;
}
.pathPassed {
    stroke: #121217;
    transition: all 8s linear;
}
.pathPoint {
    fill: white;
}

// Boards
.board {
    @include unselectable;

    position: absolute;
    z-index: 1;
    padding: 5px 7px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1;
    color: black;
    text-transform: uppercase;
    background: white;
    background: linear-gradient(-25deg, #c4987a 33%, #ffd5bf 73%);
    border-radius: $border-radius;
    transform: translate(-50%, -50%) scale(1);
    animation: divBorn 0.3s ease-in-out;
    &.boardFinish {
        color: #c4987a;
        background: black;
        border: 1px solid #c4987a;
    }
}

@keyframes divBorn {
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    70% {
        transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
        transform: translate(-50%, -50%) scale(1);
    }
}

// Obstacles
.obstacles {
    position: absolute;
    z-index: 1;
    height: 50px;
    transform: translate(-50%, -50%) scale(1);
    animation: divBorn 0.3s ease-in-out;
    .obstacle {
        width: 100%;
        height: 100%;
        transform: translate(0, 0);
    }
    .palanques {
        display: flex;
        justify-content: center;
        .jump {
            @include jump;

            position: relative;
            z-index: 1;
            width: 3px;
            height: 100%;
            background: white;
            border-radius: 0;
        }
    }
    .wall {
        display: flex;
        justify-content: center;
        .jump {
            @include jump;

            position: relative;
            z-index: 1;
            width: 8px;
            height: 100%;
            border-radius: 0;
        }
    }
    .vertical {
        display: flex;
        justify-content: center;
        .jump {
            position: relative;
            z-index: 1;
            height: 100%;

            @include jump;
        }
    }
    .oxer {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
        }
    }
    .triple {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
        }
    }
    .oxerPolonais {
        .jump {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: space-between;
            height: 100%;
        }
        .jump > div {
            @include jump;
            &:first-child {
                transform: rotate(15deg) translateX(5px);
            }
            &:last-child {
                transform: rotate(-15deg) translateX(-5px);
            }
        }
    }
    .watter {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10px;
        width: calc(100% + 20px);
        height: 80%;
        margin: auto;
        background: rgba(0, 38, 255, 0.35);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 2px;
    }
}
.obstacleNumber {
    @include unselectable;

    position: absolute;
    z-index: 2;
    padding: 2px 5px;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: white;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    background: rgba(#121216, 0.85);
    border: 1px solid rgba(white, 0.2);
    border-radius: 4px;
    transform: translate(40%, -50%) scale(1);
    animation: numberBorn 0.3s ease-in-out;
}

@keyframes numberBorn {
    0% {
        transform: translate(40%, -50%) scale(0);
    }
    70% {
        transform: translate(40%, -50%) scale(1.2);
    }
    100% {
        transform: translate(40%, -50%) scale(1);
    }
}
</style>
