const digitsRE = /(\d{3})(?=\d)/g

export function currency(value, currency, decimals, rate) {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return ''
    if (value !== 0 && rate) {
        value = value * rate
    }
    currency = currency != null ? currency : 'CHF'
    currency += ' ' //ugly fix to add a space after the currency
    decimals = decimals != null ? decimals : 2
    let stringified = Math.abs(value / 100).toFixed(decimals)
    let _int = decimals ? stringified.slice(0, -1 - decimals) : stringified
    let i = _int.length % 3
    let head = i > 0 ? _int.slice(0, i) + (_int.length > 3 ? "'" : '') : ''
    let _float = decimals ? stringified.slice(-1 - decimals) : ''
    let sign = value < 0 ? '-' : ''
    return sign + currency + head + _int.slice(i).replace(digitsRE, "$1'") + _float
}

export function capitalize(string) {
    if (typeof string !== 'string') return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function capitalizeAll(string) {
    if (typeof string !== 'string') return ''
    return string.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}
