<script>
import { mapGetters } from 'vuex'
import lottie from 'lottie-web'
import Plyr from 'plyr'
import 'plyr/dist/plyr.css'
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
// import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css'
import i18n from '@src/i18n'
import * as animationDataMoveFeatures from '@assets/animations/move-features.json'
import * as animationDataMovePlaced from '@assets/animations/move-placed.json'
import * as animationDataMoveTrajectory from '@assets/animations/move-trajectory.json'
import * as animationDataMovePush from '@assets/animations/move-push.json'
import * as animationDataHorseTrajectoryEN from '@assets/animations/move-horse-trajectory-EN.json'
import * as animationDataHorseTrajectoryFR from '@assets/animations/move-horse-trajectory-FR.json'
import BadgesAppStores from '@components/badges-app-stores'
import BadgesWebapp from '@components/badges-webapp'
import Discount from '@components/Discount.vue'
import SessionVideo from '@components/SessionVideo.vue'
import AmbassadorModal from '@components/modal/ambassadorModal.vue'
import IntroModal from '@components/modal/introModal.vue'
import design from '@design'

export default {
    page: {
        title: 'Move',
    },
    components: {
        BadgesAppStores,
        BadgesWebapp,
        Splide,
        SplideSlide,
        Discount,
        SessionVideo,
        AmbassadorModal,
        IntroModal,
    },
    data() {
        return {
            splideOptions: {
                ambassadors: {
                    rewind: true,
                    gap: '1rem',
                    autoplay: true,
                    pauseOnHover: true,
                    arrows: false,
                    interval: 10000,
                    perPage: 2,
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                    },
                },
                testimonials: {
                    rewind: true,
                    gap: '1rem',
                    autoplay: true,
                    pauseOnHover: true,
                    arrows: false,
                    interval: 10000,
                    perPage: 3,
                    breakpoints: {
                        1000: {
                            perPage: 2,
                        },
                        730: {
                            perPage: 1,
                        },
                    },
                },
                intro: {
                    rewind: true,
                    gap: '1rem',
                    autoplay: true,
                    pauseOnHover: true,
                    arrows: false,
                    interval: 10000,
                    perPage: 3,
                    breakpoints: {
                        640: {
                            perPage: 1,
                        },
                    },
                },
            },
            showAmbassadorModal: false,
            currentAmbassador: null,
            showIntroModal: false,
            currentIntro: null,
        }
    },
    computed: {
        ...mapGetters('country', ['country']),
        webAppMoveUrl() {
            return process.env.VUE_APP_ROOT_APP
        },
        locale() {
            return i18n.locale
        },
    },
    mounted() {
        lottie.loadAnimation({
            container: this.$refs.animationMoveTrajectory,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationDataMoveTrajectory.default,
        })
        lottie.loadAnimation({
            container: this.$refs.animationMoveFeatures,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationDataMoveFeatures.default,
        })
        lottie.loadAnimation({
            container: this.$refs.animationMovePlaced,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationDataMovePlaced.default,
        })
        lottie.loadAnimation({
            container: this.$refs.animationMovePush,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animationDataMovePush.default,
        })
        lottie.loadAnimation({
            container: this.$refs.animationHorseTrajectory,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData:
                i18n.locale === 'fr' ? animationDataHorseTrajectoryFR.default : animationDataHorseTrajectoryEN.default,
        })

        window.fbq('track', 'ViewContent', {
            content_ids: ['1808169002646148'],
            content_name: 'Move Pro',
            content_type: 'product',
        })

        if (this.$route.hash) {
            //we use a timeout to be sure the whole page is loaded
            setTimeout(() => {
                this.scrollTo(this.$route.hash.slice(1))
            }, 500)
        }
    },
    methods: {
        openAmbassadorModal(ambassador) {
            this.currentAmbassador = ambassador
            this.showAmbassadorModal = true
        },
        openIntroModal(intro) {
            this.currentIntro = intro
            this.showIntroModal = true
        },
        openSubscriptionLink(link) {
            window.open(link, '_blank')
        },
        scrollTo(name) {
            this.$refs[name]?.$el?.scrollIntoView({ behavior: 'smooth' })
        },
    },
    watch: {
        locale: {
            handler(newLocale) {
                this.$refs.animationHorseTrajectory.innerHTML = ''

                lottie.loadAnimation({
                    container: this.$refs.animationHorseTrajectory,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData:
                        newLocale === 'fr'
                            ? animationDataHorseTrajectoryFR.default
                            : animationDataHorseTrajectoryEN.default,
                })
            },
            deep: true,
        },
    },
    beforeDestroy() {
        lottie.destroy()
    },
}
</script>

<template>
    <div :class="$style.page">
        <!-- Hero -->
        <BaseSection :hero="true">
            <Discount :class="$style.discount" />

            <BaseSectionHeroMedia>
                <img src="@assets/vector/logo-move-pro.svg" alt="Alogo" :class="$style.logo" />
            </BaseSectionHeroMedia>

            <BaseHeroTitles :intro="$t('products.move.intro.intro')" :class="$style.introTitle" />

            <div :class="$style.explanationIntroModal">
                {{ $t('products.move.intro.explanation') }}
            </div>

            <BaseSectionSubsection style="margin-top: 15px">
                <splide :options="splideOptions.intro">
                    <splide-slide
                        v-for="(intro, indexIntro) in Object.values($t('products.move.intro.list'))"
                        :key="`intro_${indexIntro}`"
                    >
                        <div @click="openIntroModal(intro)">
                            <div :class="$style.introContainer">
                                <img
                                    :src="require(`@assets/images/intro/${$i18n.locale}/${intro['image-name']}`)"
                                    :class="$style.splideImg"
                                    alt="intro"
                                />
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </BaseSectionSubsection>

            <BaseSectionSubsection>
                <BaseHeroTitles :intro="$t('products.move.hero.intro')" />
            </BaseSectionSubsection>

            <BaseSectionHeroMedia>
                <div :class="$style.heroImage">
                    <div :class="[$style.heroDetails, $style.heroDetailsGps]">
                        {{ $t('products.move.hero.localization.part1') }}<br />
                        {{ $t('products.move.hero.localization.part2') }}<br />
                        {{ $t('products.move.hero.localization.part3') }}
                    </div>
                    <div :class="[$style.heroDetails, $style.heroDetailsGyro]">
                        {{ $t('products.move.hero.gyro') }}<br />
                    </div>
                    <div :class="[$style.heroDetails, $style.heroDetailsSize]">
                        {{ $t('products.move.hero.weight') }}
                        <span v-if="$i18n.locale === 'en'">
                            {{ $t('products.move.hero.weight-bis') }}
                        </span>
                    </div>
                </div>
            </BaseSectionHeroMedia>
            <BaseSubsectionWrapper type="full" position="center">
                <BaseSectionParagraph position="center" size="big">
                    <BaseTypographyOverviewSectionIntro :class="$style.heroIntroParagraphs">
                        <span>{{ $t('products.move.hero.paragraph.before-purple') }}</span>
                        <span :class="$style.purple">{{ $t('products.move.hero.paragraph.purple-txt') }}</span>
                        <span>{{ $t('products.move.hero.paragraph.after-purple') }}</span>
                    </BaseTypographyOverviewSectionIntro>
                </BaseSectionParagraph>
            </BaseSubsectionWrapper>

            <div :class="[$style.feiBadge, $style.smallText]">
                <div>
                    <img
                        :src="require(`@assets/images/badges/authorized-competitions-${$i18n.locale}.png`)"
                        alt="authorized"
                        :class="$style.google"
                    />
                </div>
                <div>
                    {{ $t('products.move.hero.FEI') }}
                </div>
            </div>
        </BaseSection>
        <!-- Features -->
        <div :class="$style.features">
            <div ref="animationMoveTrajectory" :class="$style.featuresMedia" />
            <BaseSection>
                <BaseTypographyOverviewSectionHeadline :class="$style.featuresHeadline">
                    {{ $t('products.move.features.headline') }}
                </BaseTypographyOverviewSectionHeadline>
                <!-- Features : Curve -->
                <div :class="$style.featuresCurve">
                    <div :class="[$style.featuresTextOverview, $style.featuresTextOverviewHeight]">
                        {{ $t('products.move.features.curve.overview1.part1') }}
                        <span>{{ $t('products.move.features.curve.overview1.part2') }}</span>
                    </div>
                    <div :class="[$style.featuresTextOverview, $style.featuresTextOverviewVelocity]">
                        {{ $t('products.move.features.curve.overview2.part1') }}
                        <span>{{ $t('products.move.features.curve.overview2.part2') }}</span>
                    </div>
                    <div :class="[$style.featuresTextOverview, $style.featuresTextOverviewAngle]">
                        {{ $t('products.move.features.curve.overview4.part1') }}
                        <span>{{ $t('products.move.features.curve.overview4.part2') }}</span>
                    </div>
                    <div :class="[$style.featuresTextOverview, $style.featuresTextOverviewFrappe]">
                        {{ $t('products.move.features.curve.overview3.part1') }}
                        <span>{{ $t('products.move.features.curve.overview3.part2') }}</span>
                    </div>
                </div>

                <BaseSubsectionWrapper position="center">
                    <div :class="$style.featuresParagraph">
                        <!-- Features : Always go further -->
                        <BaseSectionParagraph>
                            <BaseTypographyOverviewSectionSubheadline>
                                {{ $t('products.move.features.always.paragraph.subheadline') }}
                            </BaseTypographyOverviewSectionSubheadline>
                            <BaseTypographyOverviewSectionIntro>
                                {{ $t('products.move.features.always.paragraph.intro') }}
                            </BaseTypographyOverviewSectionIntro>
                        </BaseSectionParagraph>
                    </div>
                </BaseSubsectionWrapper>
            </BaseSection>
        </div>

        <!-- Technology -->
        <BaseSection :class="$style.technology">
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.technology.headline') }}
            </BaseTypographyOverviewSectionHeadline>

            <BaseSectionSubsection>
                <!-- Technology : Accuracy -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.technology.accuracy.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.technology.accuracy.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <div ref="animationMoveFeatures" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <BaseSectionMedia :class="$style.animationHorseTrajectory">
                    <div ref="animationHorseTrajectory" />
                </BaseSectionMedia>

                <!-- Technology  -->
                <!-- Partnership -->
                <BaseBadge
                    :text="$t('products.move.technology.partnership.badge').toUpperCase()"
                    :light="true"
                    :class="$style.badge"
                    :color="$style.globalColor"
                />
                <BaseSubsectionWrapper position="left">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.technology.partnership.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            <div :class="$style.bold">
                                {{ $t('products.move.technology.partnership.intro') }}
                            </div>
                            <div>
                                {{ $t('products.move.technology.partnership.body') }}
                            </div>
                            <div :class="$style.bold">
                                {{ $t('products.move.technology.partnership.footer') }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/photos/partnership.png" :class="$style.partnershipImage" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- RMSG : Trusted by FEI -->
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.technology.trusted-FEI.title') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            <div>
                                <div
                                    v-for="(text, indexText) in $t('products.move.technology.trusted-FEI.texts')"
                                    :key="`text_trusted_FEI_${indexText}`"
                                    :class="$style.trustedFeiTexts"
                                >
                                    {{ text }}
                                </div>
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/photos/Andrew.jpg" :class="$style.trustedFei" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- Technology : Scientific validation -->
                <BaseSubsectionWrapper v-show="$te('products.move.technology.validation.subheadline')">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.technology.validation.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.technology.validation.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <SessionVideo
                            src="https://www.youtube.com/watch?v=wF1_alqDuSc&list=PLnUWCMxzvEqgeNapkD_xjaLpQAAVL5k5G"
                        />

                        <div :class="$style.documentation">
                            <div :class="$style.bold">
                                {{ $t('products.move.technology.validation.publications.title') }}
                            </div>

                            <ul>
                                <li>
                                    <BaseLink href="/documents/Alogo_scientific_validation_sensors_2023.pdf">
                                        {{ $t('products.move.technology.validation.publications.article5') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <!-- <BaseLink href="/documents/XXX.pdf"> -->
                                    {{ $t('products.move.technology.validation.publications.article3') }}
                                    <!-- </BaseLink> -->
                                </li>
                                <li>
                                    <BaseLink href="/documents/icel2023_Weishaupt_Roepstorff_Montavon_Deillon.pdf">
                                        {{ $t('products.move.technology.validation.publications.article4') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink
                                        href="/documents/SRMDM_1_2021_AI and GPS sensor technology for 3D analyses in the biomechanics of jumping horses_Montavon et al.pdf"
                                    >
                                        {{ $t('products.move.technology.validation.publications.article1') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink href="/documents/IEEE_SpliTech2021_Schaetti.pdf">
                                        {{ $t('products.move.technology.validation.publications.article2') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink href="/documents/EquineVeterinaryJournalAlogo.pdf">
                                        {{ $t('products.move.technology.validation.publications.article6') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink href="/documents/AlogoFrontiersVeterinaryScience.pdf">
                                        {{ $t('products.move.technology.validation.publications.article7') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink href="/documents/AlogoBennMontavon.FuerstPferdeHeilkunde2023.pdf">
                                        {{ $t('products.move.technology.validation.publications.article8') }}
                                    </BaseLink>
                                </li>
                            </ul>
                        </div>
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Health -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.health.headline') }}
            </BaseTypographyOverviewSectionHeadline>

            <BaseSectionSubsection>
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseBadge
                            :text="$t('products.move.health.badge').toUpperCase()"
                            :light="true"
                            :class="$style.badge"
                            :color="$style.globalColor"
                        />
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.health.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.health.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                        <BaseTypographyOverviewSectionIntro>
                            <div :class="$style.healthButtons">
                                <BaseLink :to="{ name: 'health' }">
                                    <div class="button">
                                        {{ $t('products.move.health.buttons.learn-more') }}
                                    </div>
                                </BaseLink>
                                <BaseLink href="mailto:info@alogo-analysis.ch" target="_top">
                                    <div class="button">
                                        {{ $t('products.move.health.buttons.contact') }}
                                    </div>
                                </BaseLink>
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img src="@assets/photos/health.png" :class="$style.healthImage" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Heart rate -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.heart-rate.headline') }}
            </BaseTypographyOverviewSectionHeadline>

            <BaseSectionSubsection>
                <!-- Heart rate : monitor your rate -->
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.heart-rate.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro :class="$style.spaceX">
                            <div
                                v-for="(text, index) in $t('products.move.heart-rate.intro')"
                                :key="'heart-rate-intro' + index"
                            >
                                {{ text }}
                            </div>
                        </BaseTypographyOverviewSectionIntro>

                        <BaseTypographyOverviewSectionIntro>
                            <div :class="$style.polarButtons">
                                <BaseLink :to="{ name: 'polar' }" target="_top">
                                    <div class="button" style="display: inline-flex">
                                        {{ $t('products.move.heart-rate.buttons.learn-more') }}
                                    </div>
                                </BaseLink>

                                <!-- <BaseLink :to="{ name: 'horsetrack' }" target="_top">
                                    <div class="button" style="display: inline-flex">
                                            {{ $t('products.move.heart-rate.buttons.get-one') }}
                                    </div>
                                </BaseLink> -->
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img :src="require(`@assets/images/Polar/on-horse.jpg`)" :class="$style.polarImage" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Accessibility -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline position="center">
                {{ $t('products.move.accessibility.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <!-- Accessibility : Simplified or detailed display -->
                <BaseSubsectionWrapper type="full">
                    <BaseSectionParagraph size="full" position="center">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.accessibility.simplified.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            <div>
                                {{ $t('products.move.accessibility.simplified.intro.text') }}
                                <BaseLink
                                    v-show="$te('products.move.accessibility.simplified.intro.link')"
                                    :href="webAppMoveUrl"
                                >
                                    {{ $t('products.move.accessibility.simplified.intro.link') }}
                                </BaseLink>
                            </div>
                            <BadgesAppStores :inFooter="false" :class="$style.badgesAppStores" />
                            <!-- <BadgesWebapp :inFooter="false" display="move" :class="$style.badgeWebapp" /> -->
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                </BaseSubsectionWrapper>

                <BaseSectionMedia :class="$style.accessibilityAppMockup">
                    <img :src="require(`@assets/images/mockup-move-apps-${$i18n.locale.toUpperCase()}.png`)" />
                </BaseSectionMedia>

                <!-- Accessibility : Placed -->
                <BaseSubsectionWrapper type="full" position="center">
                    <BaseSectionParagraph position="center" size="big">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.accessibility.placed.suheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.accessibility.placed.intro') }}
                        </BaseTypographyOverviewSectionIntro>

                        <div v-show="$te('products.move.accessibility.placed.asterisk')" :class="$style.asterisk">
                            {{ $t('products.move.accessibility.placed.asterisk') }}
                        </div>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <div ref="animationMovePlaced" :class="$style.accessibilityAnimationPlaced" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- Accessibility : Start with a wink -->
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.accessibility.start.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.accessibility.start.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <div ref="animationMovePush" :class="$style.accessibilityMovePush" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <!-- Accessibility : Team Working -->
                <BaseSubsectionWrapper :class="$style.teamWorking">
                    <BaseSectionParagraph>
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.accessibility.team.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.accessibility.team.intro') }}
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img :class="$style.teamWorkingMedia" src="@assets/photos/ecosystem-team.jpg" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Universal -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline position="center">
                {{ $t('products.move.universal.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <!-- Universal : disciplines -->
            <BaseSectionSubsection>
                <BaseSubsectionWrapper type="full" position="center">
                    <BaseSectionParagraph position="center" size="big">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.universal.disciplines.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <ul :class="$style.universalList">
                            <li>
                                <img src="@assets/vector/bounce-white.svg" />
                                <span>{{ $t('products.move.universal.disciplines.list.jumping.title') }}</span>
                                <p>
                                    {{ $t('products.move.universal.disciplines.list.jumping.intro') }}
                                </p>
                            </li>
                            <li>
                                <img src="@assets/vector/dressage-white.svg" />
                                <span>{{ $t('products.move.universal.disciplines.list.dressage.title') }}</span>
                                <p>
                                    {{ $t('products.move.universal.disciplines.list.dressage.intro') }}
                                </p>
                            </li>
                            <li>
                                <img src="@assets/vector/regulation-white.svg" />
                                <span>{{ $t('products.move.universal.disciplines.list.gymnastic.title') }}</span>
                                <p>
                                    {{ $t('products.move.universal.disciplines.list.gymnastic.intro') }}
                                </p>
                            </li>
                            <li>
                                <img src="@assets/vector/wood-white.svg" />
                                <span>{{ $t('products.move.universal.disciplines.list.crosscountry.title') }}</span>
                                <p>
                                    {{ $t('products.move.universal.disciplines.list.crosscountry.intro') }}
                                </p>
                            </li>
                            <li>
                                <img src="@assets/vector/forest-white.svg" />
                                <span>{{ $t('products.move.universal.disciplines.list.hobbies.title') }}</span>
                                <p>
                                    {{ $t('products.move.universal.disciplines.list.hobbies.intro') }}
                                </p>
                            </li>
                        </ul>
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>

            <!-- Universal : followup -->
            <BaseSectionSubsection>
                <BaseSubsectionWrapper>
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.universal.followup.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            {{ $t('products.move.universal.followup.intro') }}
                        </BaseTypographyOverviewSectionIntro>

                        <div :class="$style.documentation">
                            <div :class="$style.bold">
                                {{ $t('products.move.universal.followup.documentation.title') }}
                            </div>

                            <BaseLink :href="`/documents/MovePRO_DailyRoutine_${$i18n.locale.toUpperCase()}.pdf`">
                                {{ $t('products.move.universal.followup.documentation.text') }}
                            </BaseLink>
                        </div>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.followupMedia">
                        <img src="@assets/photos/followup.jpeg" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Subscription -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline position="left"> Premium </BaseTypographyOverviewSectionHeadline>
            <!-- Subscription -->
            <BaseSectionSubsection>
                <BaseBadge
                    ref="subscription"
                    :text="$t('products.move.subscription.badge').toUpperCase()"
                    :light="true"
                    :class="$style.badge"
                    :color="$style.globalSubscriptionColor"
                />
                <BaseSubsectionWrapper position="left">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionSubheadline>
                            {{ $t('products.move.subscription.subheadline') }}
                        </BaseTypographyOverviewSectionSubheadline>
                        <BaseTypographyOverviewSectionIntro>
                            <div :class="$style.bold">
                                {{ $t('products.move.subscription.intro') }}
                            </div>
                            <ul :class="$style.featuresList">
                                <li>
                                    <div :class="$style.featureTick" /> {{ $t('products.move.subscription.feature1') }}
                                </li>
                                <li>
                                    <div :class="$style.featureTick" /> {{ $t('products.move.subscription.feature2') }}
                                </li>
                                <li>
                                    <div :class="$style.featureTick" /> {{ $t('products.move.subscription.feature3') }}
                                </li>
                            </ul>

                            <div :class="$style.demolink" @click="$store.dispatch('modal/toggleModal')">
                                {{ $t('modal-session-demo.title') }}
                            </div>

                            <div :class="$style.buttonsSubscription">
                                <div
                                    class="button"
                                    @click="() => openSubscriptionLink($t('subscription.link'))"
                                    style="display: inline-flex"
                                    :class="$style.buttonSubscription"
                                >
                                    <div>
                                        {{ $t('products.move.subscription.button.subscribe') }}
                                    </div>
                                </div>
                                <div
                                    class="button"
                                    @click="() => openSubscriptionLink($t('subscription.pricing-link'))"
                                    style="display: inline-flex"
                                    :class="$style.buttonSubscription"
                                >
                                    <div>
                                        {{ $t('subscription.learnmore') }}
                                    </div>
                                </div>
                                <!-- <div
                                    class="button"
                                    @click="$store.dispatch('modal/toggleModal')"
                                    style="display: inline-flex"
                                    :class="$style.buttonSubscription"
                                >
                                    <div>
                                        {{ $t('products.move.subscription.button.demo') }}
                                    </div>
                                </div> -->
                            </div>
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia>
                        <img
                            :src="require(`@assets/images/subscription/subscription-${$i18n.locale.toUpperCase()}.png`)"
                            :class="$style.subscriptionImage"
                        />

                        <div :class="$style.asterisk">
                            {{ $t('products.move.subscription.asterisk') }}
                        </div>
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Ambassadors -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.ambassadors.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <splide :options="splideOptions.ambassadors">
                    <splide-slide
                        v-for="(ambassador, indexAmbassador) in Object.values(
                            $t('products.move.ambassadors.list')
                        ).sort((a, b) => a.order - b.order)"
                        :key="`ambassador_${indexAmbassador}`"
                    >
                        <div @click="openAmbassadorModal(ambassador)">
                            <div :class="$style.ambassadorsContainer">
                                <img
                                    :src="require(`@assets/photos/ambassadors/${ambassador.name}.jpg`)"
                                    :class="[$style.splideImg, $style.ambassadorImg]"
                                    alt="ambassador"
                                />
                                <div :class="$style.ambassadorsName">
                                    <div>{{ ambassador.name }}</div>

                                    <div :class="$style.ambassadorsFlag">
                                        {{ ambassador.flag }}
                                    </div>
                                </div>
                                <div :class="$style.ambassadorsClickText">
                                    {{ $t('products.move.ambassadors.click-more-info') }}</div
                                >
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Testimonials -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.testimonials.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <splide :options="splideOptions.testimonials">
                    <splide-slide
                        v-for="(testimonial, indexTestimonial) in Object.values(
                            Object.fromEntries(Object.entries($t('products.move.testimonials.list')).sort())
                        )"
                        :key="`testimonial_${indexTestimonial}`"
                    >
                        <div>
                            <div :class="$style.testimonialContainer">
                                <img
                                    :src="require(`@assets/photos/testimonials/${indexTestimonial + 1}.png`)"
                                    :class="[$style.splideImg, $style.testimonialImg]"
                                    alt="testimonial"
                                />
                                <div :class="$style.testimonialText">
                                    <div>{{ testimonial.text }}</div>
                                </div>
                                <div :class="$style.testimonialTitle">
                                    <div :class="$style.testimonialName">{{ testimonial.name }}</div>
                                    <div :class="$style.testimonialRole">{{ testimonial.role }}</div>
                                </div>
                            </div>
                        </div>
                    </splide-slide>
                </splide>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Media -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline position="center">
                {{ $t('products.move.media.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <BaseSubsectionWrapper position="right">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionIntro :class="$style.mediaTitle">
                            Podcast Sport Horse Series
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.mediaVideo">
                        <SessionVideo src="https://www.youtube.com/watch?v=Rdx9xpAthxo&t=121s" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <BaseSubsectionWrapper :class="$style.media">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionIntro position="right" :class="$style.mediaTitle">
                            RTS Sport Matin
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.mediaVideo">
                        <SessionVideo src="https://www.youtube.com/watch?v=2vhB84KPuv0&t=2s" />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>

                <BaseSubsectionWrapper position="right" :class="$style.media">
                    <BaseSectionParagraph size="half">
                        <BaseTypographyOverviewSectionIntro :class="$style.mediaTitle">
                            Podcast Equiratings
                        </BaseTypographyOverviewSectionIntro>
                    </BaseSectionParagraph>
                    <BaseSectionMedia :class="$style.mediaVideo">
                        <SessionVideo
                            src="https://www.youtube.com/watch?v=neiYu1LCgbQ&list=PLnUWCMxzvEqhZ5rbRkMd22gc3aC8taasa"
                        />
                    </BaseSectionMedia>
                </BaseSubsectionWrapper>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Characteristics -->
        <BaseSection>
            <BaseTypographyOverviewSectionHeadline>
                {{ $t('products.move.characteristics.headline') }}
            </BaseTypographyOverviewSectionHeadline>
            <BaseSectionSubsection>
                <BaseSectionTable>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.box.header') }}
                        </template>
                        <template slot="cell">
                            <ul>
                                <li>
                                    {{ $t('products.move.characteristics.list.box.cell.sensor') }}
                                </li>
                                <li>
                                    {{ $t('products.move.characteristics.list.box.cell.holder') }}
                                </li>
                                <li>
                                    {{ $t('products.move.characteristics.list.box.cell.martingale') }}
                                </li>
                                <li>
                                    {{ $t('products.move.characteristics.list.box.cell.meter') }}
                                </li>
                                <li>
                                    {{ $t('products.move.characteristics.list.box.cell.cable') }}
                                </li>
                                <li>
                                    <BaseLink
                                        :href="`/documents/ALOGO_Depliant_QuickStart_capteur_2021_${$i18n.locale.toUpperCase()}.pdf`"
                                    >
                                        {{ $t('products.move.characteristics.list.box.cell.doc') }}
                                    </BaseLink>
                                </li>
                                <li>
                                    <BaseLink
                                        :href="`/documents/ALOGO_Flyer_Quick_Start_105x148mm_BD_${$i18n.locale.toUpperCase()}.pdf`"
                                    >
                                        {{ $t('products.move.characteristics.list.box.cell.steps') }}
                                    </BaseLink>
                                </li>
                            </ul>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.chipset.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.chipset.cell.gyro') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.chipset.cell.gps') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.resistance.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.resistance.cell') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.connectivity.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.connectivity.cell.btle') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.connectivity.cell.usb') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.dimensions.header') }}
                        </template>
                        <template slot="cell">
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.length.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.length.intro') }}
                                </p>
                            </div>
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.height.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.height.intro') }}
                                </p>
                            </div>
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.width.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.width.intro') }}
                                </p>
                            </div>
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.weight.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.dimensions.cell.weight.intro') }}
                                </p>
                            </div>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.alimentation.header') }}
                        </template>
                        <template slot="cell">
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.alimentation.cell.utilisation.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.alimentation.cell.utilisation.intro') }}
                                </p>
                            </div>
                            <p>
                                {{ $t('products.move.characteristics.list.alimentation.cell.battery') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.alimentation.cell.usb') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.memory.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.memory.cell.part1') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.memory.cell.part2') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.utilisation.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.utilisation.cell.part1') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.utilisation.cell.part2') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.config.header') }}
                        </template>
                        <template slot="cell">
                            <p>
                                {{ $t('products.move.characteristics.list.config.cell.id') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.config.cell.internet') }}
                            </p>
                            <p>
                                {{ $t('products.move.characteristics.list.config.cell.smartphone') }}
                            </p>
                        </template>
                    </BaseSectionTableRow>
                    <BaseSectionTableRow>
                        <template slot="header">
                            {{ $t('products.move.characteristics.list.langs.header') }}
                        </template>
                        <template slot="cell">
                            <div :class="$style.group">
                                <strong>
                                    {{ $t('products.move.characteristics.list.langs.cell.title') }}
                                </strong>
                                <p>
                                    {{ $t('products.move.characteristics.list.langs.cell.intro') }}
                                </p>
                            </div>
                        </template>
                    </BaseSectionTableRow>
                </BaseSectionTable>
            </BaseSectionSubsection>
        </BaseSection>

        <!-- Buy Button -->
        <div v-if="country.products && country.products.move_pro" :class="$style.floatingBuy">
            <div class="container">
                <div :class="$style.floatingBuyContent">
                    <div :class="$style.floatingBuyTitles">
                        <BaseLink
                            :href="`/documents/ALOGO_Brochure_12_pages_MOVE_PRO_150x150mm_${$i18n.locale.toUpperCase()}_2021_light.pdf`"
                        >
                            <span :class="$style.title"> Alogo Performance </span>
                        </BaseLink>
                        <span> {{ $t('products.move.subscription.price', { price: 'CHF 70' }) }} </span>
                        <!-- <span v-if="country.products.move_pro.price_cut" :class="$style.discount">
                            <span>{{ country.products.move_pro.price_cut | currency }}</span>
                            <span
                                :style="{
                                    'background-color': country.products.move_pro.event.color,
                                }"
                                >{{ country.products.move_pro.price | currency }}</span
                            >
                        </span>
                        <span v-else>
                            <span>{{ country.products.move_pro.price | currency }}</span>
                        </span>
                        <div v-if="country.currency" :class="$style.convertedPriceText">
                            ~
                            {{
                                country.products.move_pro.price
                                    | currency(country.currency.code, 2, country.currency.exchange_rate)
                            }}
                        </div> -->
                    </div>

                    <div :class="$style.buttons">
                        <div
                            class="button"
                            @click="() => openSubscriptionLink($t('subscription.link'))"
                            style="display: inline-flex"
                        >
                            <img
                                :class="$style.mobileButtons"
                                src="@assets/vector/add-cart.svg"
                                width="25"
                                alt="subscribe"
                            />
                            <div :class="$style.desktopButtons">
                                {{ $t('products.move.floating.buttons.subscribe') }}
                            </div>
                        </div>

                        <div
                            class="button"
                            @click="() => openSubscriptionLink($t('subscription.pricing-link'))"
                            style="display: inline-flex"
                        >
                            <div :class="$style.mobileButtons">
                                {{ $t('subscription.learnmore') }}
                            </div>
                            <div :class="$style.desktopButtons">
                                {{ $t('subscription.learnmore') }}
                            </div>
                        </div>

                        <div>
                            <div
                                class="button white"
                                style="display: inline-flex"
                                @click="$store.dispatch('modal/toggleModal')"
                            >
                                <div :class="$style.mobileButtons"> Demo </div>
                                <div :class="$style.desktopButtons">
                                    {{ $t('products.move.floating.buttons.session-demo') }}
                                </div>
                            </div>
                        </div>

                        <!-- <div>
                            <BaseLink
                                :href="`/documents/ALOGO_Brochure_12_pages_MOVE_PRO_150x150mm_${$i18n.locale.toUpperCase()}_2021_light.pdf`"
                            >
                                <div class="button white" style="display: inline-flex">
                                    {{ $t('products.move.floating.buttons.booklet') }}
                                </div>
                            </BaseLink>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- Ambassador modal -->
        <AmbassadorModal
            :currentAmbassador="currentAmbassador"
            :showAmbassadorModal="showAmbassadorModal"
            @close="showAmbassadorModal = false"
        />

        <!-- Intro modal -->
        <IntroModal :currentIntro="currentIntro" :showIntroModal="showIntroModal" @close="showIntroModal = false" />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.page {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.47059;
    letter-spacing: -0.022em;
}

.logo {
    width: 500px;
    margin-top: -90px;
}

.spaceX {
    * > * & :not(:first-child) {
        margin-top: 30px;
    }
}

// Hero
.heroIntroParagraphs {
    @include mobile {
        text-align: left;
    }
}
.heroDetails {
    position: absolute;
    font-family: $font-family-heading;
    font-size: 25px;
    font-weight: 300;
    line-height: 1;
    text-align: left;

    @include mobile {
        font-size: 15px;
    }

    @include tablet-only {
        font-size: 20px;
    }
    &::after {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        margin-top: 5px;
        content: '';
        background: $color-link;
        border-radius: $border-radius-round;
        box-shadow: 0 0 0 5px rgba(98, 73, 255, 0.25);
        animation: detailsPulse 2s infinite ease;

        @include mobile {
            width: 4px;
            height: 4px;
        }

        @include tablet-only {
            width: 7px;
            height: 7px;
        }
    }
    &.heroDetailsGps {
        top: 10%;
        left: 105%;

        @include mobile {
            left: 102%;
        }
    }
    &.heroDetailsGyro {
        top: 50%;
        right: 100%;
        text-align: right;
        &::after {
            right: 0;
        }
    }
    &.heroDetailsSize {
        bottom: 15%;
        left: 83%;

        > span {
            font-size: 12px;

            @include mobile {
                font-size: 10px;
            }
        }
    }
}
.heroImage {
    position: relative;
    width: 501px;
    height: 784px;
    margin-top: 24px;
    background-image: url('~@assets/images/move-hero-large.png');
    background-repeat: no-repeat;
    background-size: 501px 784px;

    @include mobile {
        width: 207px;
        height: 324px;
        margin-top: 62px;
        margin-bottom: 15px;
        background-image: url('~@assets/images/move-hero-small.png');
        background-size: 207px 324px;
    }

    @include tablet-only {
        width: 302px;
        height: 472px;
        margin-top: 36px;
        margin-bottom: 10px;
        background-image: url('~@assets/images/move-hero-medium.png');
        background-size: 302px 472px;
    }
}
.heroPromo {
    display: inline-block;
    padding: 3px 10px;
    text-align: center;
    border: 2px solid rgba($color-text, 0.25);
    border-radius: $border-radius;

    @include mobile {
        margin-top: 35px;
    }
}

// Features
.features {
    position: relative;
    width: 100%;
}
.featuresCurve {
    position: relative;
    height: 900px;

    @include mobile {
        height: 350px;
    }
}
.featuresHeadline {
    position: absolute;
    top: 145px;
    z-index: 2;
    text-shadow: 0 15px 50px black;

    @include mobile {
        position: relative;
        top: auto;
    }
}
.featuresMedia {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(#000 0%, rgba(0, 0, 0, 0.9) 20%, rgba(0, 0, 0, 0.9) 80%, #000),
        url('~@assets/vector/grid-white.svg');

    @include mobile {
        bottom: auto;
    }
}
.featuresTextOverview {
    position: absolute;
    font-family: $font-family-heading;
    font-size: 25px;
    font-weight: 300;
    color: $color-text;

    @include mobile {
        font-size: 20px;
    }
    span {
        display: block;
        font-size: 20px;
        font-weight: 200;
        letter-spacing: 0.009em;

        @include mobile {
            font-size: 15px;
        }
    }
    &.featuresTextOverviewHorsetrack {
        @include mobile {
            top: 100%;
        }

        @include tablet-only {
            top: 100%;
        }

        @include desktop {
            top: 100%;
        }
    }
    &.featuresTextOverviewHeight {
        @include mobile {
            top: 10%;
            right: auto;
            left: 0;
        }

        @include tablet-only {
            top: 25%;
            right: 3%;
            width: 290px;
        }

        @include desktop {
            top: 25%;
            left: 80%;
            text-align: right;
        }
    }
    &.featuresTextOverviewVelocity {
        top: 30%;
        right: 60%;
        text-align: right;

        @include mobile {
            top: 35%;
            right: 0;
        }
    }
    &.featuresTextOverviewAngle {
        right: 75%;
        bottom: 20%;
        text-align: right;

        @include mobile {
            top: 50%;
            right: auto;
            left: 0;
            text-align: left;
        }
    }
    &.featuresTextOverviewFrappe {
        top: 110%;
        left: 23%;

        @include tablet-only {
            top: 100%;
            left: 10%;
        }

        @include mobile {
            top: 80%;
            right: 0;
            left: auto;
            text-align: right;
        }
    }
}
.featuresParagraph {
    @include tablet {
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: flex-end;
    }
}
.technology {
    margin-top: 90px;

    @include mobile {
        margin-top: 60px;
    }

    @include tablet-only {
        margin-top: 40px;
    }
}

.trustedFeiTexts:nth-child(odd) {
    margin-bottom: 30px;
}

.animationHorseTrajectory {
    margin-bottom: 80px;
}

// Health
.health-buttons {
    * > * & :first-child {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

// Heart rate
.polarButtons {
    * > * & :not(:first-child) {
        margin-left: 10px;
    }
}

.polarImage,
.subscriptionImage,
.partnershipImage,
.trustedFei,
.healthImage {
    width: 100%;
    border-radius: $border-radius-large;
}

// Accessibility
.accessibilityAppMockup img {
    width: 100%;
    margin: 50px 0;
}
.accessibilityMovePush {
    transform: rotate(180deg);
}
.badgesAppStores {
    margin-top: 45px;
}
.badgeWebapp {
    margin-top: 45px;
}
.teamWorking {
    margin-top: 200px;

    @include mobile {
        margin-top: 150px;
    }
}
.teamWorkingMedia {
    width: 100%;
    border-radius: $border-radius-large;
}

// Universal
.universalList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        height: 300px;
        padding: 50px;
        margin: 10px;
        background: $color-background-dark;
        border-radius: 50px;

        @include mobile {
            width: calc(50% - 10px);
            padding: 15px;
            margin: 5px;
            border-radius: 18px;
        }
        img {
            width: 4rem;
            height: 4rem;

            @include mobile {
                width: 3rem;
                height: 3rem;
            }
        }
        span {
            display: block;
            margin-top: 15px;
            font-family: $font-family-heading;
            font-size: 20px;
            font-weight: 700;

            @include mobile {
                font-size: 18px;
            }
        }
        p {
            text-align: center;

            @include mobile {
                font-size: 14px;
            }
        }
    }
}
.followupMedia {
    img {
        width: 100%;
        border-radius: $border-radius-large;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }
}

// Animations
@keyframes detailsPulse {
    0% {
        box-shadow: 0 0 0 0 rgba($color-link, 0.7);
    }
    70% {
        box-shadow: 0 0 0 20px rgba($color-link, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba($color-link, 0);
    }
}

// Technology
img.technologyPuce,
img.technologyPuceMobile {
    border-radius: 18px;
}
img.technologyPuce {
    @include mobile {
        display: none;
    }
}
img.technologyPuceMobile {
    border-radius: 18px;

    @include tablet {
        display: none;
    }
}
.technologyInjuries {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 540px;

        @include mobile {
            height: 380px;
        }
    }
}

// ambasadors
.splideImg {
    margin-bottom: 10px;
    transition: transform 0.2s;
    width: 100%;
    border-radius: 10px;

    &.ambassadorImg {
        opacity: 0.6;
    }

    &.testimonialImg {
        opacity: 0.45;
        filter: blur(2px);
    }

    &:hover {
        transform: scale(1.05);
    }
}

.ambassadorsContainer,
.introContainer {
    position: relative;
    text-align: center;
    cursor: pointer;
    margin-bottom: 40px;
}

.ambassadorsName {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
}

.ambassadorsFlag {
    font-size: 35px;
}

.ambassadorsClickText {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

// testimonials
.testimonialContainer {
    position: relative;
    text-align: start;
    margin-bottom: 40px;
}

.testimonialText {
    position: absolute;
    top: 10px;
    padding: 0 10px;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    line-height: 28px;
}

.testimonialTitle {
    position: absolute;
    padding: 0 8px;
    bottom: -15px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .testimonialRole {
        font-size: 10px;
    }

    .testimonialName {
        font-size: 25px;
    }
}

// media
.media {
    margin-top: 50px !important;
}

.mediaTitle {
    @include mobile {
        margin-bottom: -20px;
        text-align: left !important;
    }
}

// characteristics
.group {
    strong {
        font-size: 0.85em;
        opacity: 0.5;
    }
    &:not(:first-child) {
        margin-top: 10px;
    }
}

.demolink {
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
    color: $color-text;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

// Fixed Buy
.floatingBuy {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #171717f5;
    border-top: 1px solid #272727;

    .floatingBuyContent {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 0;
    }
    .floatingBuyTitles {
        white-space: nowrap;
        .title {
            color: white;
            display: block;
            font-size: 20px;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
        }

        .discount {
            span:first-child {
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 5px;
                font-size: 14px;
                text-decoration: line-through;
                opacity: 0.8;
            }

            span:last-child {
                text-align: center;
                align-self: center;
                padding: 7px 10px;
                font-weight: 600;
                color: $color-text;
                white-space: nowrap;
                border-radius: $border-radius;
            }
        }
    }
}
.horsetrackLink {
    display: inline-block;
    margin-top: 9px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.38105;
    letter-spacing: 0.011em;
    text-shadow: 0 0 12px black;
}

.badge {
    width: fit-content;
    margin-bottom: 10px;
    margin-left: 0px;
}
.buttons {
    display: flex;
    justify-content: flex-end;
    flex-flow: wrap;

    > div {
        margin-top: 5px;
        margin-right: 10px;
    }

    @include mobile {
        padding: 0 20px;
    }

    .mobileButtons {
        @include tablet {
            display: none;
        }
    }

    .desktopButtons {
        @include mobile {
            margin-left: 0px;
            display: none;
        }
    }
}

.purple {
    color: $color-link;
}

.bold {
    font-weight: 800;
    margin-bottom: 20px;
    margin-top: 10px;
}

.documentation {
    margin-top: 50px;

    ul li {
        font-style: italic;
        list-style-type: circle;
    }
}

.asterisk {
    font-style: italic;
    font-size: 12px;
}

.smallText {
    font-style: italic;
    font-size: 15px;
    text-align: center;
}
.convertedPriceText {
    font-style: italic;
    font-size: 12px;
}

.feiBadge {
    margin-top: 100px;

    img {
        width: 30%;
        margin: auto;

        @include mobile {
            width: 60%;
        }

        @include tablet {
            width: 30%;
        }
    }

    div + div {
        margin-top: 15px;
    }
}

.explanationIntroModal {
    margin-top: 30px;
    font-size: 11px;
    text-align: center;
    font-style: italic;
}

.introTitle {
    margin-top: -30px;
}

.discount {
    margin-top: -80px;
    margin-bottom: -30px;
    @include mobile {
        margin-bottom: 10px;
        margin-top: -50px;
    }

    @include tablet {
        margin-top: -50px;
    }
}

.featuresList {
    li {
        margin-top: 10px;
    }
}
.featureTick {
    display: inline-block;
    transform: rotate(45deg);
    height: 24px;
    width: 12px;
    border-bottom: 3px solid $color-success;
    border-right: 3px solid $color-success;
    margin-right: 10px;
}
.buttonsSubscription {
    display: flex;
    justify-content: flex-start;
    flex-flow: wrap;

    > div {
        margin-top: 5px;
        margin-right: 10px;
    }

    .buttonSubscription {
        background: $color-subscription;
    }
}
</style>
