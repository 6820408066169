<template>
    <div :class="[$style.appStores, this.inFooter ? $style.footer : $style.noFooter]">
        <a v-if="['all', 'move'].includes(display)" :href="webAppMoveUrl" target="_blank">
            <img :src="require(`@assets/images/badges/webapp-move-pro.png`)" alt="WebApp Move" :class="$style.move" />
        </a>

        <a v-if="['all', 'live'].includes(display)" :href="webAppLiveUrl" target="_blank">
            <img :src="require(`@assets/images/badges/webapp-live.png`)" alt="WebApp Move" :class="$style.live" />
        </a>
    </div>
</template>

<script>
export default {
    name: 'BadgesAppStores',
    props: {
        inFooter: {
            type: Boolean,
            default: false,
        },
        display: {
            type: String,
            default: 'all',
            validator(value) {
                return ['all', 'live', 'move'].includes(value)
            },
        },
    },
    computed: {
        webAppMoveUrl() {
            return process.env.VUE_APP_ROOT_APP
        },
        webAppLiveUrl() {
            return process.env.VUE_APP_ROOT_LIVE_APP
        },
    },
}
</script>

<style lang="scss" module>
@import '@design';

.appStores {
    position: relative;

    img {
        display: inline-flex;

        &:nth-child(1) {
            margin-left: 10px;
        }
    }
    &.footer {
        .move {
            width: 100px;
        }
        .live {
            width: 100px;
        }
    }
    &.noFooter {
        .move {
            width: 130px;
        }
        .live {
            width: 130px;
        }
    }
}
</style>
