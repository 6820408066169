import axios from 'axios'
import store from '@state/store'
import i18n from '@src/i18n'

const http = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: { 'Content-Type': 'application/json' },
})

http.interceptors.request.use(
    function (config) {
        const token = store.state.auth.accessToken
        const locale = i18n.locale
        if (token) config.headers.Authorization = `Bearer ${token}`
        if (locale) config.headers['X-localization'] = locale
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

export default http
