<script>
import { capitalize } from '@src/filters'

export default {
    props: {
        country: {
            type: String,
            required: true,
        },
    },
    computed: {
        flagClass() {
            return `flag${capitalize(this.country)}`
        },
    },
}
</script>

<template>
    <div :class="[$style.flag, $style[flagClass]]" />
</template>

<style lang="scss" module>
.flag {
    display: inline-block;
    width: 21px;
    height: 15px;
    vertical-align: -2px;
    background-image: url('~@assets/vector/flag-icons.svg');
}

.flagAu {
    background-position: -196px -10px;
}
.flagAd {
    background-position: -10px -10px;
}
.flagAr {
    background-position: -196px -10px;
}
.flagBr {
    background-position: -351px -10px;
}
.flagIn {
    background-position: -475px -60px;
}
.flagIs {
    background-position: -41px -85px;
}
.flagQa {
    background-position: -382px -135px;
}
.flagRu {
    background-position: -475px -135px;
}
.flagZa {
    background-position: -196px -185px;
}
.flagUa {
    background-position: -506px -160px;
}
.flagUy {
    background-position: -41px -185px;
}
.flagAe {
    background-position: -41px -10px;
}
.flagCa {
    background-position: -382px -10px;
}
.flagCn {
    background-position: -41px -35px;
}
.flagDk {
    background-position: -227px -35px;
}
.flagEs {
    background-position: -382px -35px;
}
.flagEe {
    background-position: -320px -35px;
}
.flagSe {
    background-position: -41px -160px;
}
.flagDe {
    background-position: -165px -35px;
}
.flagAt {
    background-position: -165px -10px;
}
.flagLu {
    background-position: -41px -110px;
}
.flagLv {
    background-position: -72px -110px;
}
.flagBe {
    background-position: -227px -10px;
}
.flagFi {
    background-position: -444px -35px;
}
.flagFr {
    background-position: -506px -35px;
}
.flagHk {
    background-position: -227px -60px;
}
.flagHu {
    background-position: -320px -60px;
}
.flagIe {
    background-position: -382px -60px;
}
.flagIt {
    background-position: -72px -85px;
}
.flagJp {
    background-position: -196px -85px;
}
.flagNo {
    background-position: -506px -110px;
}
.flagNz {
    background-position: -10px -135px;
}
.flagNl {
    background-position: -475px -110px;
}
.flagGb {
    background-position: -41px -60px;
}
.flagSg {
    background-position: -72px -160px;
}
.flagPt {
    background-position: -320px -135px;
}
.flagPl {
    background-position: -227px -135px;
}
.flagCh {
    background-position: -475px -10px;
}
.flagGr {
    background-position: -134px -60px;
}
.flagUs {
    background-position: -10px -185px;
}
.flagRo {
    background-position: -444px -135px;
}
.flagBg {
    background-position: -289px -10px;
}
.flagSk {
    background-position: -134px -160px;
}
.flagSi {
    background-position: -103px -160px;
}
.flagCz {
    background-position: -134px -35px;
}
.flagMt {
    background-position: -258px -110px;
}
.flagLt {
    background-position: -10px -110px;
}
.flagCy {
    background-position: -258px -185px;
}
.flagHr {
    background-position: -227px -185px;
}
</style>
