<script>
export default {
    props: {
        color: {
            type: String,
            default: '#ffffff',
        },
    },
    computed: {
        fill() {
            if (this.color === 'gradient') {
                return `url(#g${this._uid})`
            } else if (this.$route.meta.appearance === 'dark') {
                return '#ffffff'
            }
            return this.color
        },
    },
}
</script>

<template>
    <img src="@assets/images/logo-alogo-blanc.png" alt="Alogo" />
</template>
