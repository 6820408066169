export default [
    {
        path: '/entreprise',
        component: require('@views/entreprise').default,
        children: [
            {
                path: '',
                name: 'entreprise',
                component: require('@views/entreprise-about').default,
            },
            {
                path: 'jobs',
                name: 'entreprise-jobs',
                component: require('@views/entreprise-jobs').default,
            },
        ],
    },
]
