<template>
    <div :class="[$style.appStores, this.inFooter ? $style.footer : $style.noFooter]">
        <a href="https://apps.apple.com/ch/app/alogo/id1533246447?l=fr" target="_blank">
            <img
                :src="require(`@assets/images/badges/apple-store-${$i18n.locale}-white.svg`)"
                alt="Apple Store badge"
                :class="$style.apple"
            />
        </a>
        <a href="https://play.google.com/store/apps/details?id=io.alogo.app" target="_blank">
            <img
                :src="require(`@assets/images/badges/google-play-${$i18n.locale}-black.png`)"
                alt="Google Play badge"
                :class="$style.google"
            />
        </a>
    </div>
</template>

<script>
export default {
    name: 'BadgesAppStores',
    props: {
        inFooter: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" module>
@import '@design';

.appStores {
    position: relative;

    @include mobile {
        margin-top: 20px;
    }

    img {
        display: inline-flex;

        &:nth-child(1) {
            margin-left: 10px;
        }
    }
    &.footer {
        .apple {
            width: 100px;
        }
        .google {
            width: 106px;
        }
    }
    &.noFooter {
        .apple {
            width: 130px;
        }
        .google {
            width: 137px;
        }
    }
}
</style>
