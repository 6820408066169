<script>
import Modal from '@components/modal/modal'

export default {
    components: { Modal },
    props: {
        currentAmbassador: {
            type: Object,
            default: null,
        },
        showAmbassadorModal: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<template>
    <Modal v-if="currentAmbassador" :isOpen="showAmbassadorModal" @close="$emit('close')">
        <template #header>
            <div> {{ currentAmbassador.name }} {{ currentAmbassador.flag }} </div>
        </template>

        <template #body>
            <img
                :src="require(`@assets/photos/ambassadors/${currentAmbassador.name}.jpg`)"
                alt="ambassador"
                :class="$style.img"
            />

            <div :class="$style.role"> {{ currentAmbassador.role }} </div>

            <div :class="$style.text">
                <span>
                    <img
                        src="@assets/vector/open-quote.svg"
                        width="25px"
                        style="display: inline; vertical-align: middle; margin-right: 3px; margin-bottom: 3px"
                        alt="quote"
                    />
                    {{ currentAmbassador.testimonial }}</span
                >
            </div>

            <div>
                <h2>{{ $t('products.move.ambassadors.achievements') }}:</h2>

                <ul>
                    <li
                        v-for="(achievement, indexAchievement) in currentAmbassador.achievements"
                        :key="`achievement-${indexAchievement}`"
                    >
                        {{ achievement }}
                    </li>
                </ul>
            </div>

            <div :class="$style.horses">
                <h2>{{ $t('products.move.ambassadors.current-horses') }}:</h2>

                <ul>
                    <li v-for="(horse, indexHorse) in currentAmbassador.horses" :key="`horse-${indexHorse}`">
                        {{ horse }}
                    </li>
                </ul>
            </div>
        </template>
    </Modal>
</template>

<style lang="scss" module>
.text {
    font-style: italic;
    margin-bottom: 30px;
    display: flex;
}

.role {
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 15px;
}

.horses {
    margin-top: 20px;
}

.img {
    border-radius: 10px;
    width: 100%;
}

h2 {
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 5px;
}
</style>
