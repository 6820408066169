<script>
export default {
    props: {
        link: {
            type: String,
            required: true,
        },
    },
}
</script>

<template>
    <div :class="$style.wrapper">
        <div :class="$style.hero">
            <div class="container">
                <div :class="$style.content">
                    <h2 :class="$style.headline">
                        <slot name="title" />
                    </h2>
                    <h3 :class="$style.subhead">
                        <slot name="subtitle" />
                    </h3>
                    <div :class="$style.links">
                        <slot name="link">
                            <BaseLink :to="{ name: link }" class="more" :class="$style.more">
                                {{ $t('home.blocs.more') }}
                            </BaseLink>
                        </slot>
                    </div>
                </div>
            </div>
            <div :class="$style.media">
                <slot name="media" />
            </div>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: relative;
    width: 100%;
    height: calc(692px + 12px);
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    border-color: #fff;
    border-style: solid;
    border-top-color: #222;
    border-top-width: 6px;
    border-right-width: 0;
    border-bottom-color: #222;
    border-bottom-width: 6px;
    border-left-width: 0;
    @include mobile {
        height: calc(580px + 12px);
    }
}
.hero {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 692px;
    overflow: hidden;
    color: white;
    text-align: center;
    @include mobile {
        height: 580px;
    }
}
.content {
    position: relative;
    z-index: 4;
    flex-grow: 0;
    padding-top: 55px;
}
.headline {
    font-size: 56px;
    font-weight: 600;
    line-height: 1.07143;
    letter-spacing: -0.005em;

    @include mobile {
        font-size: 50px;
    }
}
.subhead {
    max-width: 30em;
    margin-top: 6px;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33349;
    letter-spacing: 0.009em;

    @include mobile {
        font-size: 20px;
    }
}
.headline,
.subhead {
    position: relative;
    display: block;
    flex-shrink: 1;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
}
.links {
    display: inline-block;
    margin-top: 9px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.38105;
    letter-spacing: 0.011em;
}
.media {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: visible;
}
.more {
    text-shadow: 0 0 12px black;
}
</style>
