<script>
import { broadcastComputed, broadcastMethods } from '@state/helpers'

export default {
    computed: {
        ...broadcastComputed,
    },
    methods: {
        ...broadcastMethods,
    },
}
</script>

<template>
    <div class="container">
        <BaseCompetitors v-if="competitorsNext.length" :competitors="competitorsNext" :round="epreuve.round" />
        <div v-else :class="$style.competitorsEmpty">
            <span> Aucun </span>
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';
</style>
