<script>
import http from '@state/http'
import FormError from '@components/form-error'
import FormSuccess from '@components/form-success'

export default {
    components: {
        FormError,
        FormSuccess,
    },
    data() {
        return {
            form: {
                email: {
                    name: 'email-newsletter',
                    value: '',
                    hasFocus: false,
                },
            },
            submitting: false,
            response: null,
            errors: {},
            messages: {},
        }
    },
    methods: {
        handleChange(evt) {
            console.log('handleChange :: ', evt.target.name)
            this.clearError(evt.target.name)
        },
        handleInvalid(evt) {
            console.log('handleInvalid :: ', evt.target.name)
            this.errors = {
                ...this.errors,
                [evt.target.name]: evt.target.validationMessage,
            }
        },
        clearError(key) {
            this.errors.api = undefined
            this.messages = {}
            if (key) {
                this.errors = {
                    ...this.errors,
                    [key]: undefined,
                }
            }
        },
        submit() {
            const self = this
            self.clearError()
            self.submitting = true

            return http
                .post('newsletter/subscribe', {
                    email: self.form.email.value,
                })
                .then((response) => {
                    console.log(response)
                    self.messages = response.data.message
                })
                .catch((error) => {
                    console.log(error.response)
                    let message = error.response.data?.errors?.email
                        ? error.response.data.errors.email.join(', ')
                        : 'Subscription failed.'
                    self.errors = {
                        ...self.errors,
                        api: message,
                    }
                })
                .then(function () {
                    self.submitting = false
                })
        },
    },
}
</script>

<template>
    <div>
        <form id="form-newsletter" class="form" @submit.prevent="submit" @change="handleChange">
            <div class="field">
                <div
                    class="fieldWrapper"
                    :class="{
                        hasFocus: form.email.hasFocus,
                        hasValue: form.email.value,
                        hasError: errors[form.email.name],
                    }"
                >
                    <BaseLabel :name="form.email.name" :label="$t('form.newsletter.email')" />
                    <input
                        :id="form.email.name"
                        v-model="form.email.value"
                        :name="form.email.name"
                        type="text"
                        required
                        class="input"
                        @focus="form.email.hasFocus = true"
                        @blur="form.email.hasFocus = false"
                    />
                </div>
            </div>
            <BaseButton type="submit" :disabled="submitting">
                <BaseSpinner v-if="submitting" :class="$style.spinner" />
                <span :class="submitting ? $style.buttonDisappeared : ''">
                    {{ $t('form.newsletter.button') }}
                </span>
            </BaseButton>
        </form>
        <FormError name="newsletter-errors" :errors="errors" />
        <FormSuccess name="newsletter-success" :messages="messages" />
    </div>
</template>

<style lang="scss" scoped>
form {
    display: flex;
    flex-direction: row;
}

div.field {
    padding: 0 !important;
    line-height: 14px !important;
    width: 200px;
}

button {
    padding: 0 8px;
    margin-top: 2px;
    margin-left: 5px;
}
input {
    font-size: 12px !important;
}
</style>

<style lang="scss" module>
@import '@design';

.buttonDisappeared {
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
    transform: scale(0.5);
}

.spinner {
    padding: 5px;
}
</style>
