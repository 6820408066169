<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import CountryFlag from '@components/country-flag'
import Newsletter from '@components/newsletter'
import BadgesWebapp from '@components/badges-webapp'
import BadgesAppStores from '@components/badges-app-stores.vue'
import { mixin as clickaway } from 'vue-clickaway'
import { getSupportedLocales } from '@utils/i18n/supported-locales'
import { i18n, loadLocaleMessages } from '@src/i18n'

export default {
    components: {
        CountryFlag,
        Newsletter,
        BadgesAppStores,
        BadgesWebapp,
    },
    mixins: [clickaway],
    data() {
        return {
            popupCountries: false,
            popupLangs: false,
            locales: getSupportedLocales(),
        }
    },
    computed: {
        ...mapGetters('country', ['country']),
        ...mapState('country', {
            countries: (state) => state.countries,
        }),
        linkLang() {
            if (i18n.locale !== 'fr') {
                return i18n.locale + '/'
            }
            return ''
        },
        currentLocale() {
            return this.locales.find((el) => el.value === i18n.locale)
        },
        webAppMoveUrl() {
            return process.env.VUE_APP_ROOT_APP
        },
        webAppLiveUrl() {
            return process.env.VUE_APP_ROOT_LIVE_APP
        },
    },
    methods: {
        ...mapActions('country', ['saveCountry', 'getCountries']),
        togglePopupCountries() {
            this.closePopupLangs()
            this.popupCountries = !this.popupCountries
        },
        closePopupCountries() {
            if (this.popupCountries) {
                this.popupCountries = false
            }
        },
        togglePopupLangs() {
            this.closePopupCountries()
            this.popupLangs = !this.popupLangs
        },
        closePopupLangs() {
            if (this.popupLangs) {
                this.popupLangs = false
            }
        },
        selectNewCountry(country) {
            this.closePopupCountries()
            this.saveCountry(country)
        },
        changeLocale(locale) {
            this.closePopupLangs()
            loadLocaleMessages(locale)
            this.getCountries()
        },
        contactInfo() {
            window.fbq('track', 'Contact')
        },
    },
}
</script>

<template>
    <footer :class="$style.wrapper">
        <div class="container">
            <div :class="$style.footer">
                <div :class="$style.column">
                    <ul :class="$style.metaNav">
                        <!-- COUNTRIES -->
                        <!-- <li
                            v-on-clickaway="closePopupCountries"
                            :class="[$style.select, $style.country, popupCountries ? $style.popupActive : '']"
                        >
                            <a :class="[$style.rootLink, $style.itemCountry]" @click="togglePopupCountries">
                                <svg width="13" height="13">
                                    <path
                                        d="M1.543,7L6,7,5.979,11.462a0.536,0.536,0,0,0,1.016.24l4.941-9.931a0.537,0.537,0,0,0-.72-0.721L1.3,5.985A0.537,0.537,0,0,0,1.543,7Z"
                                    />
                                </svg>
                                <span>
                                    {{ country.name }}
                                </span>
                            </a>
                            <div :class="[$style.popup, $style.countryPicker]">
                                <h4 :class="$style.popupTitle">
                                    {{ $t('footer.langs.countries') }}
                                </h4>
                                <ul :class="$style.optionList">
                                    <li
                                        v-for="countryAvailable in countries"
                                        :key="countryAvailable.value"
                                        @click="selectNewCountry(countryAvailable)"
                                    >
                                        <a :class="countryAvailable.value === country.value ? $style.selected : ''">
                                            <CountryFlag :country="countryAvailable.value" :class="$style.flag" />
                                            <span>{{ countryAvailable.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li> -->
                        <!-- LANG -->
                        <li
                            v-on-clickaway="closePopupLangs"
                            :class="[$style.select, $style.language, popupLangs ? $style.popupActive : '']"
                        >
                            <h5 :class="$style.linksTitle">
                                {{ $t('footer.langs.languages') }}
                            </h5>
                            <a :class="[$style.rootLink, $style.itemLanguage]" @click="togglePopupLangs">
                                <svg width="13" height="13">
                                    <path
                                        d="M8.079,9.837L6.116,12.3A0.654,0.654,0,0,1,5,11.841V9.852C2.488,9.351,1,7.6,1,5.5,1,3.015,3.087,1,6.5,1S12,3.015,12,5.5A4.5,4.5,0,0,1,8.079,9.837Z"
                                    ></path>
                                </svg>
                                {{ currentLocale.name }}
                            </a>
                            <div :class="[$style.popup, $style.languagePicker]">
                                <ul :class="$style.optionList">
                                    <li
                                        v-for="locale in locales"
                                        :key="locale.value"
                                        @click="changeLocale(locale.value)"
                                    >
                                        <a :class="$i18n.locale === locale.value ? $style.selected : ''">
                                            <span>{{ locale.name }}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>

                        <li :class="$style.socialNetworks">
                            <BaseLink href="https://www.facebook.com/alogoanalysis/">
                                <img src="@assets/vector/social/facebook.svg" />
                            </BaseLink>
                            <BaseLink href="https://www.instagram.com/alogoanalysis_official/">
                                <img src="@assets/vector/social/instagram.svg" />
                            </BaseLink>
                            <BaseLink href="https://www.tiktok.com/@alogo_analysis">
                                <img src="@assets/vector/social/tiktok.svg" />
                            </BaseLink>
                            <BaseLink
                                href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQHKoTrTZPmfbAAAAWowtZ4QMANOQzoBKP9WSPXiUgSsbZISWVdnY_k1zzwceDnNonuzzhjhD3I7bphSzK2x77TdvAryOG-QGZPvWAQJpJ_gMloBn5FWXwU1RgDDKYGWxYYVtcw=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Falogo-analysis%2F"
                            >
                                <img src="@assets/vector/social/linkedin.svg" />
                            </BaseLink>
                            <BaseLink href="https://wa.me/message/GHWAFS66RAJ5K1">
                                <img src="@assets/vector/social/whatsapp.svg" />
                            </BaseLink>
                            <BaseLink href="https://www.youtube.com/alogoanalysis">
                                <img src="@assets/vector/social/youtube.svg" />
                            </BaseLink>
                        </li>
                    </ul>

                    <BadgesAppStores :inFooter="true" />
                    <BadgesWebapp :inFooter="true" display="all" :class="$style.badgeWebapp" />

                    <ul :class="$style.newsletter">
                        <li>
                            <div>
                                {{ $t('form.newsletter.title') }}
                            </div>
                            <Newsletter />
                        </li>
                    </ul>
                </div>

                <div :class="$style.links">
                    <div :class="$style.column">
                        <h5 :class="$style.linksTitle">
                            {{ $t('footer.products.title') }}
                        </h5>
                        <ul>
                            <li>
                                <BaseLink :to="{ name: 'products' }"> Move Pro </BaseLink>
                            </li>
                            <li>
                                <BaseLink :to="{ name: 'where-to-buy' }">
                                    {{ $t('footer.products.where-to-buy') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="webAppMoveUrl"> WebApp Move Pro </BaseLink>
                            </li>
                            <li>
                                <BaseLink :to="{ name: 'services' }"> Live </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="webAppLiveUrl"> WebApp Live </BaseLink>
                            </li>
                        </ul>
                    </div>

                    <div :class="$style.column">
                        <h5 :class="$style.linksTitle">
                            {{ $t('footer.entreprise.title') }}
                        </h5>
                        <ul>
                            <li>
                                <BaseLink :to="{ name: 'entreprise' }">
                                    {{ $t('footer.entreprise.list.about') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :to="{ name: 'entreprise-jobs' }">
                                    {{ $t('footer.entreprise.list.jobs') }}
                                </BaseLink>
                            </li>
                        </ul>
                    </div>

                    <div :class="$style.column">
                        <h5 :class="$style.linksTitle">
                            {{ $t('footer.ressources.title') }}
                        </h5>
                        <ul>
                            <li>
                                <BaseLink href="https://blog.alogo.io/"> Blog </BaseLink>
                            </li>
                            <li>
                                <BaseLink
                                    href="https://www.youtube.com/watch?v=NFGDpJw91kM&list=PLnUWCMxzvEqjC1nZvB0GVJN_CUbcQ91Kf"
                                >
                                    {{ $t('footer.ressources.list.tutorials') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="`/docs/${linkLang}faq/`">
                                    {{ $t('footer.ressources.list.faq') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="`/docs/${linkLang}glossary/`">
                                    {{ $t('footer.ressources.list.glossary') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :to="{ name: 'resources-documentation' }">
                                    {{ $t('footer.ressources.list.doc') }}
                                </BaseLink>
                            </li>
                            <li>
                                <a href="mailto:info@alogo-analysis.ch" @click="contactInfo">
                                    {{ $t('footer.ressources.list.contact') }}
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div :class="$style.column">
                        <h5 :class="$style.linksTitle">
                            {{ $t('footer.legal.title') }}
                        </h5>
                        <ul>
                            <li>
                                <BaseLink :href="`/docs/${linkLang}`">
                                    {{ $t('footer.legal.list.cg') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="`/docs/${linkLang}cookies.html`">
                                    {{ $t('footer.legal.list.cookies') }}
                                </BaseLink>
                            </li>
                            <li>
                                <BaseLink :href="`/docs/${linkLang}confidentiality.html`">
                                    {{ $t('footer.legal.list.confidentiality') }}
                                </BaseLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div :class="$style.copyright"> © Alogo Analysis SA </div>
    </footer>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    padding-top: 45px;
    line-height: 30px;
    border-top: 1px solid $color-background-dark-soft;
    a {
        color: $color-text;
        text-decoration: none;
        transition: color 0.1s;
    }
}
.footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.links {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    margin-top: 20px;

    @include desktop {
        margin-top: 0;
    }
}
.linksTitle {
    margin: 0 0 5px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
}
.optionList {
    display: flex;
    flex-wrap: wrap;
    li {
        width: 100%;
    }
    a {
        @include mobile {
            padding: 0 10px;
        }

        display: block;
        padding: 0 15px;
        overflow: hidden;
        line-height: 36px;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        border-radius: 4px;
        transition: color 0.1s, background-color 0.1s;
        &:hover {
            color: $color-text;
            background-color: $color-background-dark-soft;
        }
    }
    .flag {
        margin-right: 12px;
    }
    .selected {
        font-weight: 600;
        color: black;
        background: white;
        &:hover > span::before {
            background: url('~@assets/vector/checkmark-white.svg');
        }
    }
    .selected > span::before {
        display: inline-block;
        width: 14px;
        height: 14px;
        margin: 0 6px 0 -2px;
        vertical-align: -2px;
        content: '';
        background: url('~@assets/vector/checkmark-black.svg');
        background-repeat: no-repeat;
        background-size: contain;
    }
}
.metaNav {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    flex-grow: 2;
    margin-bottom: 20px;

    @include tablet {
        flex-basis: auto;
        margin: 0 0 20px;
    }
}
.metaNav > li {
    margin-right: 20px;
}
.column {
    @include mobile {
        width: 100%;
    }

    flex-basis: auto;
    margin-bottom: 20px;
}
.select {
    z-index: 499;
    svg path {
        fill: currentColor;
    }
}
.countryPicker {
    padding: 25px;

    @include mobile {
        padding: 10px;
    }
    .optionList {
        li {
            width: 50%;

            @include desktop {
                width: 25%;
            }
        }
    }
}
.languagePicker {
    padding: 10px 5px;
}
.popup {
    position: absolute;
    bottom: 40px;
    left: -5px;
    z-index: 1000;
    font-size: 15px;
    line-height: 26px;
    pointer-events: none;
    background: $color-background-dark;
    border: 1px solid $color-background-dark-soft;
    border-radius: $border-radius;
    opacity: 0;
    transition-duration: 0.25s;
    transition-property: transform, opacity;
    transform: rotate3d(1, 1, 0, 15deg);
    transform-origin: 0 100%;
    will-change: transform, opacity;
    &::before {
        position: absolute;
        bottom: -11px;
        left: 30px;
        width: 20px;
        height: 20px;
        content: '';
        background: $color-background-dark;
        border-color: $color-background-dark-soft;
        border-style: solid;
        border-width: 0 1px 1px 0;
        border-radius: 20px 0 3px 0;
        transform: rotate(45deg);
    }
}
.popupActive .popup {
    pointer-events: auto;
    opacity: 1;
    transform: none;
}
h4.popupTitle {
    display: block;
    padding: 0 15px;
    margin-bottom: 5px;
    font-family: $font-family-heading;
    font-size: 28px;
    font-weight: 800;
    line-height: 36px;
    color: $color-text;

    @include mobile {
        padding: 0 10px;
    }
}
.country {
    margin-bottom: 5px;
    .popup {
        @include mobile {
            width: calc(100vw - 30px);
        }

        @include tablet {
            width: 550px;
        }

        @include desktop {
            width: 850px;
        }
    }
}
.select {
    position: relative;
    perspective: 2000px;
    z-index: 499;
}
.rootLink {
    display: flex;
    align-items: center;
    font-weight: 600;
    cursor: pointer;

    @include unselectable;
    &:hover {
        color: rgba(white, 0.5);
    }
    svg {
        display: block;
        margin-right: 5px;
    }
}
.copyright {
    padding: 30px 0;
    margin-top: 10px;
    margin-right: 0;
    color: rgba($color-text, 0.6);
    text-align: center;
}
.socialNetworks {
    display: flex;
    margin-top: 30px;
    img {
        margin-right: 10px;
    }
}
.newsletter {
    font-weight: 600;
}
</style>
