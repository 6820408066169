<script>
import { authComputed } from '@state/helpers'
import NavBarRoutesMobile from '@components/nav-bar-routes-mobile'

export default {
    components: {
        NavBarRoutesMobile,
    },
    computed: {
        ...authComputed,
        loginUrl() {
            return process.env.VUE_APP_ROOT_APP
        },
    },
}
</script>

<template>
    <header :class="$style.wrapper">
        <div class="container">
            <div :class="$style.header">
                <RouterLink tabindex="0" tag="div" :to="{ name: 'home' }" :class="$style.logoWrapper">
                    <BaseLogo :class="$style.logo" />
                </RouterLink>
                <nav :class="$style.headerNav">
                    <ul :class="$style.wrapperNav">
                        <BaseLink :to="{ name: 'products' }" tag="li" :class="$style.link">
                            <a>{{ $t('header.nav.products') }}</a>
                        </BaseLink>
                        <BaseLink :to="{ name: 'services' }" tag="li" :class="$style.link">
                            <a>{{ $t('header.nav.services') }}</a>
                        </BaseLink>
                        <BaseLink :to="{ name: 'entreprise' }" tag="li" :class="$style.link">
                            <a>{{ $t('header.nav.entreprise') }}</a>
                        </BaseLink>
                        <BaseLink href="https://blog.alogo.io/" tag="li" :class="$style.link">
                            <a>{{ $t('header.nav.blog') }}</a>
                        </BaseLink>
                        <BaseLink :href="loginUrl" tag="li" :class="$style.link">
                            <a>{{ $t('header.nav.login') }}</a>
                        </BaseLink>
                    </ul>
                    <NavBarRoutesMobile />
                </nav>
            </div>
        </div>
    </header>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    position: absolute;
    top: 10px;
    right: 0;
    left: 0;
    z-index: 500;
    perspective: 2000px;
}
.header {
    position: relative;
    z-index: 9998;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    color: white;
    box-shadow: 1px 1px transparent;
}
.logoWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    margin: auto;

    &::after {
        position: absolute;
        top: -10px;
        right: -10px;
        bottom: -10px;
        left: -10px;
        content: '';
    }
}

.logo {
    height: 50px;
    margin-top: 15px;
    margin-left: 15px;
}
.headerNav {
    position: absolute;
    right: 0;

    @include tablet {
        flex: 1;
        position: relative;
        text-align: right;
    }
}
.wrapperNav {
    display: inline-flex;
    flex-direction: row;
    list-style-type: none;

    @include mobile {
        display: none;
    }
    li {
        &:last-child {
            margin-right: -10px;
        }
    }
}
.link {
    position: relative;
    margin-left: 12px;
    font-size: 15px;
    font-weight: 600;

    @include mobile {
        display: none;
    }
    a {
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 3.15;
        color: #fff;
        text-decoration: none;
        letter-spacing: -0.01em;
        white-space: nowrap;
        background: no-repeat;
        outline-offset: -7px;
        transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
            color: rgba(white, 0.6);
            text-decoration: none;
        }
    }
}
</style>

<style lang="scss" scoped>
.routerLinkActive a {
    color: rgba(white, 0.6);
}
</style>
