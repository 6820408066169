<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        },
        intro: {
            type: String,
            default: '',
        },
        center: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<template>
    <div :class="[$style.titles, center ? $style.titlesCentered : '']">
        <h1>
            {{ title }}
        </h1>
        <h2 v-if="intro">
            {{ intro }}
        </h2>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.titles {
    h1 {
        z-index: 3;
        font-family: $font-family-heading;
        font-size: 120px;
        font-weight: 600;
        line-height: 1;
        letter-spacing: -0.015em;

        @include mobile {
            font-size: 56px;
            letter-spacing: -0.005em;
        }
    }
    h2 {
        margin-top: 0.18em;
        font-family: $font-family-heading;
        font-size: 40px;
        font-weight: 700;
        line-height: 1.1;

        @include mobile {
            margin-top: 0.4em;
            font-size: 24px;
            line-height: 1;
            letter-spacing: 0;
        }
    }
    &.titlesCentered {
        text-align: center;
    }
}
</style>
