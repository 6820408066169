<script>
import isVisible from '@components/mixins/isVisible'

export default {
    mixins: [isVisible],
    props: {
        button: {
            type: Object,
            required: true,
        },
    },
}
</script>

<template>
    <div :class="$style.notHero">
        <div :class="$style.gradiate">
            <h4>
                <div v-if="$slots.badge" :class="[$style.badge]">
                    <span>
                        <slot name="badge"></slot>
                    </span>
                </div>
            </h4>
            <h2 :class="[$style.headline]">
                <slot name="toptitle"></slot>
            </h2>
        </div>
        <h3 :class="$style.headline">
            <slot name="title"></slot>
        </h3>
        <p :class="$style.typographyIntro">
            <slot name="intro"></slot>
        </p>
    </div>
</template>
