<script>
import { mapActions } from 'vuex'

export default {
    props: {
        competitors: {
            type: Array,
            required: true,
        },
        round: {
            type: Number,
            required: true,
        },
        draggable: {
            type: Boolean,
            default: () => false,
        },
    },
    computed: {
        competitorsDraggable: {
            get() {
                return this.competitors
            },
            set(newCompetitors) {
                this.updateCompetitorsOrder(newCompetitors)
            },
        },
    },
    methods: {
        ...mapActions('epreuves', ['updateCompetitorsOrder']),
    },
}
</script>

<template>
    <ul :class="$style.competitors">
        <li v-for="competitor in competitors" :key="competitor.id">
            <!-- Main -->
            <div :class="$style.competitorNumber">
                <span>
                    {{ competitor.rounds[round - 1].number }}
                </span>
            </div>
            <!-- Main -->
            <div :class="$style.main">
                <!-- Content -->
                <div :class="$style.competitorContent">
                    <div :class="$style.competitorRider">
                        {{ competitor.rider.name }}
                    </div>
                    <div :class="$style.competitorHorse">
                        {{ competitor.horse.name }}
                    </div>
                </div>
                <!-- Details -->
                <div :class="$style.competitorDetails">
                    <div :class="$style.detail">
                        <div :class="$style.detailTitle"> Status </div>
                        <div :class="$style.detailContent">
                            {{ competitor.rounds[round - 1].status }}
                        </div>
                    </div>
                    <div :class="$style.detail">
                        <div :class="$style.detailTitle"> Pays </div>
                        <div :class="$style.detailContent">
                            {{ competitor.rider.country }}
                        </div>
                    </div>
                    <div :class="$style.detail">
                        <div :class="$style.detailTitle"> Dossard </div>
                        <div :class="$style.detailContent">
                            {{ competitor.cnr }}
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<style lang="scss" module>
@import '@design';

.competitors {
    margin-top: 10px;
    li {
        display: flex;
        align-items: flex-start;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(black, 0.1);
        &:first-child {
            padding-top: 10px;
        }
        &:last-child {
            padding-bottom: 80px;
            border-bottom: 0;
        }
    }
    .competitorNumber {
        display: flex;
        justify-content: center;
        width: 30px;
        margin-top: 10px;
        margin-right: 10px;
        span {
            display: inline-block;
            padding: 0 5px;
            font-weight: 600;
            color: white;
            background: $color-purple;
            border-radius: $border-radius;
        }
    }
    .competitorContent {
        flex: 1;
        margin-bottom: 10px;
        line-height: 1.1;
        .competitorRider {
            font-size: 16px;
            font-weight: 600;
        }
        .competitorHorse {
            font-size: 0.95em;
            opacity: 0.8;
        }
    }
    .competitorDetails {
        display: flex;
        .detail {
            min-width: 50px;
            padding: 3px;
            text-align: center;
            background: rgba(black, 0.05);
            border-radius: $border-radius;
            &:not(:first-child) {
                margin-left: 10px;
            }
            .detailTitle {
                font-size: 10px;
                opacity: 0.8;
            }
            .detailContent {
                font-size: 12px;
            }
        }
    }
}
</style>
