export default [
    {
        path: '/resources',
        component: require('@views/resources').default,
        children: [
            {
                path: 'documentation',
                name: 'resources-documentation',
                component: require('@views/resources-documentation').default,
            },
        ],
    },
]
