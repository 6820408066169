var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.component)?_c(_vm.component,{tag:"component",staticClass:"fieldWrapper",class:{
        hasFocus: _vm.hasFocus,
        hasValue: _vm.fields[_vm.field.name],
        hasError: _vm.errors[_vm.field.name],
    },attrs:{"field":_vm.field,"value":_vm.fields[_vm.field.name]},on:{"input":function($event){return _vm.UPDATE_FIELD({
            name: _vm.field.name,
            value: $event,
        })},"focus":function($event){_vm.hasFocus = true},"blur":function($event){_vm.hasFocus = false}}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }