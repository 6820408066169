<script>
import Layout from '@layouts/main'

export default {
    components: { Layout },
    mounted() {
        this.$store.dispatch('broadcast/broadcaster')
    },
}
</script>

<template>
    <Layout>
        <div :class="$style.menu">
            <div :class="$style.menuHidder" />
            <div :class="$style.menuWrapper">
                <ul>
                    <BaseLink tag="li" :to="{ name: 'home' }"> Carte </BaseLink>
                    <BaseLink tag="li" :to="{ name: 'home' }"> Classement provisoire </BaseLink>
                    <BaseLink tag="li" :to="{ name: 'home' }"> Prochain partants </BaseLink>
                </ul>
            </div>
        </div>
        <RouterView />
    </Layout>
</template>

<style lang="scss" module>
@import '@design';

.active {
    box-sizing: border-box;
    color: $color-gold;
    border-bottom: 3px solid $color-gold;
}
.menu {
    position: relative;
    height: 40px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
}
.menuHidder {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 50px;
    height: 40px;
    pointer-events: none;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    border-bottom-right-radius: 8px;
}
.menuWrapper {
    @include unselectable;
    @include overflow-touch;

    position: relative;
    display: block;
    padding: 0;
    margin-right: -3px;
    margin-left: -3px;
    overflow-x: scroll;
    overflow-y: hidden;
    ul {
        display: inline-block;
        padding-right: 50px;
        padding-left: 11px;
        overflow: hidden;
        white-space: nowrap;
        li {
            display: inline-block;
            height: 32px;
            padding: 0 16px;
            margin-top: 8px;
            line-height: 27px;
            text-align: center;
        }
    }
}
</style>
