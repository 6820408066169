<script>
import isVisible from '@components/mixins/isVisible'

export default {
    mixins: [isVisible],
}
</script>

<template>
    <div>
        <h2
            v-observe-visibility="{
                callback: visibilityChanged,
                once: true,
            }"
            :class="[$style.typographyOverviewHeadline, reveal ? $style.reveal : '']"
        >
            <slot name="title"></slot>
        </h2>
        <p :class="[$style.typographyOverviewBody, reveal ? $style.reveal : '']">
            <slot name="subtitle"></slot>
        </p>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.typographyOverviewHeadline {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.08365;
    text-align: center;
    letter-spacing: -0.003em;
    transition-delay: 0s, 0s;
}
.typographyOverviewBody {
    max-width: 560px;
    margin-top: 0.6em;
    margin-right: auto;
    margin-left: auto;
    font-size: 21px;
    font-weight: 400;
    line-height: 1.38105;
    color: white;
    text-align: center;
    letter-spacing: 0.011em;
    transition-delay: 0.3s, 0.3s;
}
.typographyOverviewHeadline,
.typographyOverviewBody {
    @include animateText;
}
.reveal {
    opacity: 1;
    transform: translateY(0) scale(1);
}
</style>
