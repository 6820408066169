<script>
import { broadcastComputed } from '@state/helpers'

export default {
    computed: {
        ...broadcastComputed,
    },
}
</script>

<template>
    <ul :class="$style.wrapper">
        <li v-for="competitor in competitors" :key="competitor.id">
            {{ competitor.name }}
        </li>
    </ul>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    background: #121217;
    li {
        &:not(:first-child) {
            border-top: 1px solid #313131;
        }
    }
}
</style>
