<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { ucfirst } from '@state/helpers'
import FormDynamic from '@components/form-dynamic'
import FormError from '@components/form-error'

export default {
    components: {
        FormDynamic,
        FormError,
    },
    props: {
        builder: {
            type: Array,
            required: true,
        },
        action: {
            type: Function,
            required: true,
        },
        button: {
            type: String,
            default: 'Valider',
        },
        asterisk: {
            type: String,
            default: null,
        },
        backLink: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            submitting: false,
        }
    },
    computed: {
        ...mapGetters('form', ['hasErrors']),
    },
    created() {
        const fields = this.transformFormToFields()
        this.SET_FIELDS(fields)
    },
    methods: {
        ...mapActions('form', ['removeErrorIfExist']),
        ...mapMutations('form', ['SET_FIELDS']),
        submit() {
            this.submitting = true
            this.$store
                .dispatch('form/submit', this.action)
                .then((response) => {
                    this.submitting = false
                    this.$emit('success', response)
                })
                .catch((error) => {
                    this.submitting = false
                    return this.$emit('failed', error)
                })
        },
        fieldSize(size) {
            return typeof size !== 'undefined' ? 'field' + ucfirst(size) : ''
        },
        transformFormToFields() {
            return [].concat(...this.builder.map((form) => form.fields)).reduce((total, current) => {
                total[current.name] = current.value ? current.value : ''
                return total
            }, {})
        },
    },
}
</script>

<template>
    <form novalidate class="form" @submit.prevent="submit" @input="removeErrorIfExist($event.target.name)">
        <fieldset v-for="(fieldset, key) in builder" :key="key">
            <h2 v-if="fieldset.title">
                {{ fieldset.title }}
            </h2>
            <h3 v-if="fieldset.subtitle">
                {{ fieldset.subtitle }}
            </h3>
            <div class="fields">
                <div v-for="field in fieldset.fields" :key="field.name" class="field" :class="fieldSize(field.size)">
                    <FormDynamic :field="field" />
                    <FormError :name="field.name" />
                </div>
            </div>
        </fieldset>

        <div v-if="asterisk" :class="$style.asterisk">
            {{ asterisk }}
        </div>

        <div :class="$style.footer">
            <BaseLink v-if="backLink" :to="{ name: backLink.to }" :class="$style.back">
                <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" :class="$style.iconBack">
                    <path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4" />
                </svg>
                <span>
                    {{ backLink.name }}
                </span>
            </BaseLink>
            <BaseButton
                type="submit"
                :disabled="submitting || hasErrors"
                :class="[$style.button, !backLink ? 'buttonFull' : '']"
            >
                <BaseSpinner v-if="submitting" :class="$style.spinner" />
                <span :class="submitting ? $style.buttonDisappeared : ''">
                    {{ !hasErrors ? button : $t('form.button.error') }}
                </span>
            </BaseButton>
        </div>
        <FormError name="global" :class="$style.errorGlobal" />
    </form>
</template>

<style lang="scss" module>
@import '@design';

.button {
    @include mobile {
        width: 100%;
    }
    span {
        display: block;
        opacity: 1;
        transition: all 0.3s ease-in-out 0s;
        transform: scale(1);
        &.buttonDisappeared {
            opacity: 0;
            transition: all 0.3s ease-in-out 0s;
            transform: scale(0.5);
        }
    }
}
.errorGlobal {
    text-align: center;
}
.spinner {
    padding: 5px;
}
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5em;

    @include mobile {
        flex-direction: column-reverse;
    }
    .back {
        display: flex;
        align-items: center;

        @include mobile {
            padding-top: 1.5em;
            text-align: center;
        }
        .iconBack {
            width: 10px;
            height: 10px;
            margin-right: 0.25em;
            color: currentColor;
            fill: currentColor;
        }
    }
}

.asterisk {
    text-align: end;
    font-size: 12px;
    margin-top: 5px;
    color: $color-text-soft;
}
</style>
