<script>
import { mapState } from 'vuex'

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        errors: {
            type: [String, Array, Object],
            default: '',
        },
    },
    computed: {
        ...mapState('form', {
            formErrors: (state) => state.errors,
        }),
        error() {
            if (this.errors) {
                if (typeof this.errors === 'string') {
                    return [this.errors]
                }
                return this.errors
            }
            return this.formErrors[this.name]
        },
    },
}
</script>

<template>
    <div :class="[$style.wrapper, error ? $style.hasError : '']">
        <div v-for="message in error" :key="message" :class="$style.error" v-text="message" />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.wrapper {
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out 0s;
    transform: translateY(calc(-100% - 8px));
    &.hasError {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
}
.error {
    margin-top: 8px;
    font-size: 13px;
    color: $color-error;
    text-align: left;
}
</style>
