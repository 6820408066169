import http from '@state/http'
import { getState, saveState } from '@state/helpers'

export const state = {
    countries: [],
    manualCountry: getState('location.country'),
    detectedCountry: getState('location.detectedCountry'),
}

export const mutations = {
    SET_COUNTRIES(state, newCountries) {
        state.countries = newCountries
    },
    SET_MANUAL_COUNTRY(state, newCountry) {
        state.manualCountry = newCountry
    },
    SET_DETECTED_COUNTRY(state, newCountry) {
        state.detectedCountry = newCountry
    },
}

export const getters = {
    country(state) {
        var country = state.manualCountry || state.detectedCountry
        const isCountryAvailable = state.countries.some(({ value }) => value === country)
        if (!isCountryAvailable) {
            country = 'ch'
        }
        return state.countries.find(({ value }) => value === country)
    },
}

export const actions = {
    getCountries({ commit }) {
        return http
            .get('location/countries')
            .then(({ data }) => {
                const countries = data
                commit('SET_COUNTRIES', countries)
                return countries
            })
            .catch((error) => {
                console.error(error)
                commit('SET_COUNTRIES', [{ name: 'Suisse', value: 'ch' }])
            })
    },
    getVisitorCountry({ commit, state, getters }) {
        return http
            .get('location/visitorCountry')
            .then(({ data }) => {
                const countryISO = data
                commit('SET_DETECTED_COUNTRY', countryISO)
                return countryISO
            })
            .catch((error) => {
                console.error(error)
                commit('SET_DETECTED_COUNTRY', 'ch')
            })
    },
    saveCountry({ commit }, { value }) {
        commit('SET_MANUAL_COUNTRY', value)
        saveState('location.country', value)
    },
}
