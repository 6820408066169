<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        intro: {
            type: String,
            required: false,
            default: '',
        },
        overlay: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<template>
    <div :class="$style.videoWrapper">
        <video autoplay muted playsinline loop src="@assets/videos/live-hero.mp4" :class="$style.video" />
        <BaseHeroTitles v-if="title || intro" :title="title" :intro="intro" :class="$style.videoTitles" />
        <div v-if="overlay" :class="$style.videoOverlay" />
    </div>
</template>

<style lang="scss" module>
@import '@design';

.videoWrapper {
    position: relative;
}
.video {
    z-index: 1;
    display: block;
    width: 100%;
}
.videoOverlay,
.videoTitles {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.videoOverlay {
    z-index: 2;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(black, 0), rgba(0, 0, 0, 0.6), rgba(black, 1));
}
.videoTitles {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5em;
}
</style>
